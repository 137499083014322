import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import {
    signupResponseFailed,
    loginFailed,
    loginSuccess,
    resetPasswordEmailSuccess,
    resetPasswordEmailFailed,
    resetPasswordFailed,
    resetPasswordSuccess,
    setEmailPassword,
    resetPasswordToggle,
    toggleResetPasswordMessageModal,
    setOnSuccessRender
} from '../redux/actions/loginSignupAction';
import {
    SIGNUP_REQUEST, LOGIN_REQUEST,
    RESET_PASSWORD_EMAIL_REQUEST,
    RESET_PASSWORD_REQUEST,
    CLEAR_RESET_PASSWORD_MESSAGE_LOGIN,
    CHECK_RESET_PASWORD_LINK_VALID,
    TOGGLE_LOGIN_MODAL,
    TOGGLE_SIGNUP_MODAL,
    TOGGLE_FORGOT_PASSWORD_MODAL,
    RESET_PASSWORD_TOGGLE,
    HANDLE_LOGIN_SIGNUP_RESP,
    ET_SIGNUP_SUCCESS_FROM_LANDING_PAGE,
    TOGGLE_NEW_SIGNUP_MODAL,
    TOGGLE_NEW_LOGIN_MODAL
} from '../redux/actions/actionConstants';
import {
    signupApi, loginRequestApiCall,
    resetPasswordEmailApiCall,
    resetPasswordApiCall,
    checkResetPasswordLinkValidApiCall,
    otherPlatformLoginApiCall
} from '../api/loginSignupApi';
import { getCompanyProfileApiCall } from '../api/companyApi';
import {
    toggleSignupModal,
    toggleLoginModal,
    toggleForgotPasswordModalStatus,
    callHandleLoginSignupResp,
    toggleNewSignupModal,
    toggleNewLoginModal
} from '../redux/actions/loginSignupAction';
import {
    getUserProfileSuccess, setDailySearchInfo, setValutionReportsList
} from '../redux/actions/userAccountAction';
import { createStripeSession, toggleSubcriptionScreen } from '../redux/actions/subscriptionAction';
import {
    sendActivityTracking, setChoosePackageModalData, setLoading, toggleShareUrlModal, callOnloadApi
} from '../redux/actions/commonAction';
import {
    LOGIN_EVENT_PAGE,
    SIGNUP_EVENT_PAGE,
    LOGIN_ERROR_EVENT_TYPE,
    SIGNUP_ERROR_EVENT_TYPE,
    SIGNUP_SUCCESS_EVENT_TYPE,
    LOGIN_SUCCESS_EVENT_TYPE,
    FORGOT_PASSWORD_EVENT_PAGE,
    FORGOT_PASSWORD_OFF_EVENT_TYPE,
    TOGGLE_AUTO_CLICK,
    FORGOT_PASSWORD_EMAIL_SUBMIT_ERROR,
    FORGOT_PASSWORD_EMAIL_SUBMIT_SUCCESS,
    FORGOT_PASSWORD_RESPONSE_MODAL_EVENT_PAGE,
    RESET_PASSWORD_EVENT_PAGE,
    RESET_PASSWORD_LINK_VALIDITY_CHECK,
    RESET_PASSWORD_ON_EVENT_TYPE,
    RESET_PASSWORD_LINK_INVALID,
    RESET_PASSWORD_REQUEST_TRACKING,
    RESET_PASSWORD_OFF_EVENT_TYPE,
    RESET_PASSWORD_ERROR,
    COMPANY_PROFILE,
    EP_DIRECTOR_PROFILE,
    CHECK_FREE_SEARCH_EXPIRATION_AND_REDIRECT,
    EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
    ET_SUBMIT_INVESTMENT_INTEREST_BTN,
    // EP_UNKNOWN_EMAIL_CAPTURE_MODEL,
    // ET_UNKNOW_EMAIL_MODAL_OPEN,

} from '../common/trackingConstants';
import {
    getCompanyChargesRequest, toggleBuyCreditReportModal,
    noFinancialValuationModal, toggleCompanyChargesModal,
    toggleSubmitInvestmentInterestModal,
    getCompanyHouseSH01Data
} from '../redux/actions/companyAction';
import { SR_DAILY_SEARCH_LIMIT } from '../common/subscriptionConfigConst';
import {
    SHARE_REPORT_URL, TOGGLE_SUBMIT_INTEREST_MODAL_ON_LOGIN_SIGNUP_SUCCESS,
    TOGGLE_LOGIN_MODAL_ON_SUCCESS, TOGGLE_NEW_LOGIN_MODAL_ON_SUCCESS,
    TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS, TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
    TOGGLE_SIGNUP_ON_LANDING_PAGE_ON_SUCCESS,
    TOGGLE_SIGNUP_MODAL_AND_REDIRECT,
    CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
    TOGGLE_LOGIN_MODAL_AND_REDIRECT, TOGGLE_LOGIN_MODAL_SCORE_CARD,
    TOGGLE_CHOOSE_PKG_MODAL_ON_LOGIN_SIGNUP_SUCCESS,
    TOGGLE_CHARGES_MODAL_ON_LOGIN_SIGNUP_SUCCESS,
    TOGGLE_LOGIN_MODAL_VALUATION_CALCULATOR,
    OPEN_NO_FINANCIAL_VALUATION_MODAL_ON_SUCCESS,
    REDIRECT_TO_VALUATION_CALCULATOR_PAGE_ON_SUCCESS,
    CREATE_STRIPE_SESSION_ON_LOGIN_SUCCESS,
    // TOGGLE_UNKNOWN_EMAIL_MODAL_ON_SUMBIT_SUCCESS,
    CH_FILINGS_LOGIN,
    CREATE_STRIPE_SESSION_BUSINESS_PLAN_CREDITS
} from '../common/loginSignupSuccessConstants'
import { saveOrUpdateBusinessPlanOnboardApi } from '../api/businessPlanApi'
import localstorageApi from '../api/localStorageApi'
import { getValuationReportsListApi, updateBusinessPlansCreditApi } from '../api/userAccountApi';
import { handleProductPlanButtonClick } from '../redux/actions/productAction';
import { getBusinessPlanDataRequest, saveBusinessPlanDataRequest } from '../redux/actions/businessPlanTemplateAction';
import { PROSPER_USER_TYPE } from '../common/config';
import { updateBusinessPlansForCredits } from '../redux/actions/businessPlanAction';

const subscriptionModalRedux = (state) => state.subscription.subscriptionModal;
const shareUrlObj = (state) => state.common.shareUrlObj;
const onSuccessRenderRedux = (state) => state.loginSignup.onSuccessRender;
const businessPlanTemplateMenuDataFrmRedux = state=>state.businessPlanTemplate?.businessPlanTemplateMenuData || {};

export function* callSignupRequest(action) {
    try {
        yield put(setLoading({ status: true }));
        let response = null;
        if(action?.payload?.loginPlatform){
            response = yield call(otherPlatformLoginApiCall,action.payload);
        } else {
            response = yield call(signupApi, action.payload);
        }
        response.type = "SIGNUP";
        response.loginPlatform = action?.payload?.loginPlatform || "";
        yield put(callHandleLoginSignupResp(response))

    } catch (e) {
        console.error('inside signup saga', e);
        yield all([
            put(signupResponseFailed(e.message)),
            put(setLoading({ status: false })),
            put(sendActivityTracking({
                eventPage: SIGNUP_EVENT_PAGE,
                eventType: SIGNUP_ERROR_EVENT_TYPE,
                attribute1: e.message
            }))
        ])
    }
}

export function* watchSignupRequest() {
    yield takeLatest(SIGNUP_REQUEST, callSignupRequest);
}

function* callLoginRequest(action) {
    var subscriptionModal = yield select(subscriptionModalRedux)
    try {
        action.payload.serviceRequested = subscriptionModal?.serviceRequested
        action.payload.planSelected = subscriptionModal?.planSelected
        let loginRes = null;
        if(action.payload.loginPlatform){
            loginRes = yield call(otherPlatformLoginApiCall,action.payload);
        } else {
            loginRes = yield call(loginRequestApiCall, action.payload);
        }
        loginRes.type = "LOGIN";
        loginRes.loginPlatform = action?.payload?.loginPlatform || "";
        yield put(callHandleLoginSignupResp(loginRes))

    } catch (e) {
        yield put(loginFailed(e));
        yield put(sendActivityTracking({
            eventPage: LOGIN_EVENT_PAGE,
            eventType: LOGIN_ERROR_EVENT_TYPE,
            attribute1: e.message
        }));
    }
}

export function* watchLoginRequestSaga() {
    yield takeLatest(LOGIN_REQUEST, callLoginRequest);
}
export function* handleLoginSignupRespSaga(action) {
    yield put(setLoading({ status: false }));
    var subscriptionModal = yield select(subscriptionModalRedux)
    let response = action.payload
    const onSuccessRender = yield select(onSuccessRenderRedux);
    if (!onSuccessRender?.target) {
        onSuccessRender.target = "_self";
    }
    if (!response.error) {
        let latestTid = yield call(localstorageApi.getTid)
        yield all([
            put(loginSuccess(response.token)),
            put(getUserProfileSuccess(response.userInfo, response.isPomandaUser)),
            put(setDailySearchInfo(response.isFreeSearchExpired)),
            put(callOnloadApi(latestTid)),
            put(setValutionReportsList(response.valuationRptList))
        ]);

        const serviceType = onSuccessRender?.serviceType;
        if (onSuccessRender && onSuccessRender.toggleType) {
            switch (onSuccessRender.toggleType) {
                case TOGGLE_SIGNUP_MODAL_ON_SUCCESS:
                    yield put(toggleSignupModal({ status: false },
                        {
                            eventPage: SIGNUP_EVENT_PAGE,
                            eventType: SIGNUP_SUCCESS_EVENT_TYPE,
                            attribute1: response.userInfo._id,
                            attribute2: response?.loginPlatform
                        }))
                    break;
                case TOGGLE_LOGIN_MODAL_ON_SUCCESS:
                    yield put(toggleLoginModal({ status: false }, {
                        eventPage: LOGIN_EVENT_PAGE,
                        eventType: LOGIN_SUCCESS_EVENT_TYPE,
                        attribute1: response.userInfo._id,
                        attribute2: response?.loginPlatform
                    }))
                    break;
                case TOGGLE_NEW_SIGNUP_MODAL_ON_SUCCESS:
                    yield put(toggleNewSignupModal({ status: false },
                        {
                            eventPage: SIGNUP_EVENT_PAGE,
                            eventType: SIGNUP_SUCCESS_EVENT_TYPE,
                            attribute1: response.userInfo._id,
                            attribute2: response?.loginPlatform
                        }));
                    break;
                case TOGGLE_NEW_LOGIN_MODAL_ON_SUCCESS:
                    yield put(toggleNewLoginModal({ status: false }, {
                        eventPage: LOGIN_EVENT_PAGE,
                        eventType: LOGIN_SUCCESS_EVENT_TYPE,
                        attribute1: response.userInfo._id,
                        attribute2: response?.loginPlatform
                    }));
                    break;
                case TOGGLE_SIGNUP_ON_LANDING_PAGE_ON_SUCCESS:
                    yield signupResponselandingPage(onSuccessRender.url, onSuccessRender.eventPage);
                    break;
                case TOGGLE_SIGNUP_MODAL_AND_REDIRECT:
                    yield put(toggleSignupModal({ status: false },
                        {
                            eventPage: SIGNUP_EVENT_PAGE,
                            eventType: SIGNUP_SUCCESS_EVENT_TYPE,
                            attribute1: response.userInfo._id,
                            attribute2: response?.loginPlatform
                        }))
                    window.open(onSuccessRender.url, onSuccessRender.target);
                    break;
                case TOGGLE_LOGIN_MODAL_AND_REDIRECT:
                    yield put(toggleLoginModal({ status: false }, {
                        eventPage: LOGIN_EVENT_PAGE,
                        eventType: LOGIN_SUCCESS_EVENT_TYPE,
                        attribute1: response.userInfo._id,
                        attribute2: response?.loginPlatform
                    }))
                    window.open(onSuccessRender.url, onSuccessRender.target);
                    break;
                case TOGGLE_LOGIN_MODAL_SCORE_CARD:
                    yield put(toggleLoginModal({ status: false }, {
                        eventPage: LOGIN_EVENT_PAGE,
                        eventType: LOGIN_SUCCESS_EVENT_TYPE,
                        attribute1: response.userInfo._id,
                        attribute2: response?.loginPlatform
                    }))
                    break;
                case TOGGLE_LOGIN_MODAL_VALUATION_CALCULATOR:
                    yield put(toggleLoginModal({ status: false }, {
                        eventPage: LOGIN_EVENT_PAGE,
                        eventType: LOGIN_SUCCESS_EVENT_TYPE,
                        attribute1: response.userInfo._id,
                        attribute2: response?.loginPlatform
                    }))
                    let companyResponse = yield call(getCompanyProfileApiCall, onSuccessRender?.companyId, onSuccessRender?.companyName);
                    if (companyResponse) {
                        if (companyResponse.companyProfile?.financials?.profitLoss) {
                            window.open(onSuccessRender.url, "_self");
                        } else if (companyResponse.companyProfile?.isIndMixEdited) {
                            window.open(onSuccessRender.url, "_self");
                        } else {
                            yield put(noFinancialValuationModal({ toggleStatus: true }));
                        }
                    }
                    break;
                default:
                    break;
            }
            
            if (serviceType) {
                switch (serviceType) {
                    case SHARE_REPORT_URL:
                        yield shareReportAfterSignupLoginSuccess(response.userInfo, onSuccessRender);
                        break;
                    case TOGGLE_SUBMIT_INTEREST_MODAL_ON_LOGIN_SIGNUP_SUCCESS:
                        yield put(toggleSubmitInvestmentInterestModal({
                            toggleStatus: true,
                            companyId: onSuccessRender.companyId,
                            subject: onSuccessRender.subject,
                            subHeading: onSuccessRender.subHeading,
                            userMessage: onSuccessRender.userMessage,
                            eventPage: onSuccessRender.eventPage,
                            eventType: onSuccessRender.eventType,
                            mailRecipient: "SUBMIT_INTEREST_EMAIL_ADDRESS"
                        }));
                        break;
                    case TOGGLE_CHARGES_MODAL_ON_LOGIN_SIGNUP_SUCCESS:
                        yield put(getCompanyChargesRequest({ companyId: onSuccessRender.companyId, count: onSuccessRender.chargesCount }));
                        yield put(toggleCompanyChargesModal(true));
                        break;
                    case CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS:
                    case CREATE_STRIPE_SESSION_ON_LOGIN_SUCCESS:
                        {
                            yield* createStripeSessionOrShowReport(response.userInfo, onSuccessRender.stripeSessionObj, onSuccessRender.eventPage, response?.valuationRptList);
                            break;
                        }
                    case TOGGLE_CHOOSE_PKG_MODAL_ON_LOGIN_SIGNUP_SUCCESS: {
                        let userInfo = response?.userInfo;
                        if (userInfo && userInfo?.subscription?.productName === "PomandaPlus"
                            && userInfo?.subscription?.active === "yes") {
                            yield put(setChoosePackageModalData({ toggleStatus: false }));
                        }
                        break;
                    }
                    case CHECK_FREE_SEARCH_EXPIRATION_AND_REDIRECT:
                        if(response?.isFreeSearchExpired) {
                            window.open(onSuccessRender.url, "_self")
                        } else {
                            window.open(onSuccessRender.href, "_self")
                        }
                        break;
                    //Don't Remove 
                    // case TOGGLE_UNKNOWN_EMAIL_MODAL_ON_SUMBIT_SUCCESS:
                    //     yield put(toggleCommonMsgModal({
                    //         toggleStatus: true,
                    //         ...SIGNUP_SUBMIT_CLICK
                    //     }, {
                    //         eventPage: EP_UNKNOWN_EMAIL_CAPTURE_MODEL,
                    //         eventType: ET_UNKNOW_EMAIL_MODAL_OPEN
                    //     }))
                    //     break;
                    case CH_FILINGS_LOGIN:
                        window.open(onSuccessRender.pdfLink, '_blank');
                        break;
                    case CREATE_STRIPE_SESSION_BUSINESS_PLAN_CREDITS: {
                        const businessPlanTemplateMenuData = yield select(businessPlanTemplateMenuDataFrmRedux);
                        if (response?.userInfo?.businessPlansPkgSubscription?.businessPlansCount) {
                            yield put(updateBusinessPlansForCredits({
                                businessPlanTemp: {
                                    data: businessPlanTemplateMenuData,
                                    uniqueId: onSuccessRender.businessPlanBuyParam.uniqueId,
                                    userId: response.userInfo?._id
                                },
                                businessPlanOnboard: {
                                    uniqueId: onSuccessRender.businessPlanBuyParam.uniqueId,
                                    userId: response.userInfo?._id,
                                    status: "active"
                                }
                            }));
                        } else {
                            yield put(createStripeSession(onSuccessRender?.businessPlanBuyParam));
                        }
                        break;
                    }
                    default:
                        break;
                }
            }
            yield put(setOnSuccessRender(null));
        }

        if (response.stripeCreateSessionObj && response.stripeCreateSessionObj.isCreateStripeSession) {
            yield put(createStripeSession(response.stripeCreateSessionObj));
        } else {
            yield put(toggleSubcriptionScreen({
                subscriptionToggleStatus: false,
                serviceRequested: subscriptionModal.serviceRequested,
                isLoginSuccess: true
            }));
        }
        let pathName = window.location.pathname;
        if (response.isFreeSearchExpired && pathName.split("/")[1] === "company" && pathName.split("/")[1] === "director") {
            yield put(toggleSubcriptionScreen({
                subscriptionToggleStatus: true,
                serviceRequested: SR_DAILY_SEARCH_LIMIT
            }));
        }
    } else {
        yield put(response.type === "SIGNUP" ?
            signupResponseFailed(response.reason)
            : loginFailed(response.message));
        yield put(sendActivityTracking({
            eventPage: response.type === "SIGNUP" ? SIGNUP_EVENT_PAGE : LOGIN_EVENT_PAGE,
            eventType: response.type === "SIGNUP" ? SIGNUP_ERROR_EVENT_TYPE : LOGIN_ERROR_EVENT_TYPE,
            attribute1: response.message
        }));
    }

}
export function* watchHandleLoginSignupRespSaga() {
    yield takeLatest(HANDLE_LOGIN_SIGNUP_RESP, handleLoginSignupRespSaga);
}
function* callResetPasswordEmailRequest(action) {
    try {
        let response = yield call(resetPasswordEmailApiCall, action.payload.email)
        yield put(sendActivityTracking(action.payload.trackingInfo))

        if (!response.error) {
            yield all([
                put(resetPasswordEmailSuccess(response.message)),
                put(toggleForgotPasswordModalStatus(false, {
                    eventPage: FORGOT_PASSWORD_EVENT_PAGE,
                    eventType: FORGOT_PASSWORD_OFF_EVENT_TYPE,
                    attribute1: TOGGLE_AUTO_CLICK
                }))
            ]);
            yield put(sendActivityTracking({
                eventPage: FORGOT_PASSWORD_RESPONSE_MODAL_EVENT_PAGE,
                eventType: FORGOT_PASSWORD_EMAIL_SUBMIT_SUCCESS
            }));
        } else {
            yield put(resetPasswordEmailFailed(response.message));
            yield put(sendActivityTracking({
                eventPage: FORGOT_PASSWORD_RESPONSE_MODAL_EVENT_PAGE,
                eventType: FORGOT_PASSWORD_EMAIL_SUBMIT_ERROR,
                attribute1: response.message
            }));
        }
    } catch (error) {
        console.log(error);
        yield put(resetPasswordEmailFailed(error));
        yield put(sendActivityTracking({
            eventPage: FORGOT_PASSWORD_EVENT_PAGE,
            eventType: FORGOT_PASSWORD_EMAIL_SUBMIT_ERROR,
            attribute1: error.message
        }));
    }
}

export function* watchResetPasswordEmailRequest() {
    yield takeLatest(RESET_PASSWORD_EMAIL_REQUEST, callResetPasswordEmailRequest);
}

function* callResetPasswordRequest(action) {
    try {
        let response = yield call(resetPasswordApiCall, action.payload);
        yield put(sendActivityTracking({
            eventPage: RESET_PASSWORD_EVENT_PAGE,
            eventType: RESET_PASSWORD_REQUEST_TRACKING,
            attribute1: action.payload.email
        }));
        if (!response.error) {
            yield all([
                put(resetPasswordSuccess(response.message)),
                put(setEmailPassword(action.payload.email, action.payload.password)),
                put(resetPasswordToggle(false, {
                    eventPage: RESET_PASSWORD_EVENT_PAGE,
                    eventType: RESET_PASSWORD_OFF_EVENT_TYPE,
                    attribute1: TOGGLE_AUTO_CLICK
                }))
            ])
        } else {
            yield put(resetPasswordFailed(response.message));
            yield put(sendActivityTracking({
                eventPage: RESET_PASSWORD_EVENT_PAGE,
                eventType: RESET_PASSWORD_ERROR,
                attribute1: action.payload.email,
                attribute2: response.message
            }));
        }
    } catch (error) {
        console.log(error);
        yield put(resetPasswordFailed(error));
        yield put(sendActivityTracking({
            eventPage: RESET_PASSWORD_EVENT_PAGE,
            eventType: RESET_PASSWORD_ERROR,
            attribute1: action.payload.email,
            attribute2: error.message
        }));
    }
}

export function* watchResetPasswordRequest() {
    yield takeLatest(RESET_PASSWORD_REQUEST, callResetPasswordRequest);
}

function* clearResetPasswordMessageLoginSaga(action) {
    try {
        let loginResponse = yield call(loginRequestApiCall, action.payload);
        if (!loginResponse.error) {
            yield all([
                put(toggleResetPasswordMessageModal()),
                put(loginSuccess(loginResponse.token)),
                put(getUserProfileSuccess(loginResponse.userInfo, loginResponse.isPomandaUser))
            ])
        }

    } catch (error) {
        console.log(error);
        yield put(loginFailed(error));
    }
}

export function* watchClearResetPasswordMessageLogin() {
    yield takeLatest(CLEAR_RESET_PASSWORD_MESSAGE_LOGIN, clearResetPasswordMessageLoginSaga);
}

function* checkResetPasswordLinkValidSaga(action) {
    try {
        let validLinkRes = yield call(checkResetPasswordLinkValidApiCall, action.payload.email);
        yield put(sendActivityTracking({
            eventPage: RESET_PASSWORD_EVENT_PAGE,
            eventType: RESET_PASSWORD_LINK_VALIDITY_CHECK,
            attribute1: action.payload.email
        }));
        if (!validLinkRes.error) {
            yield put(resetPasswordToggle(true, {
                eventPage: RESET_PASSWORD_EVENT_PAGE,
                eventType: RESET_PASSWORD_ON_EVENT_TYPE,
                attribute1: TOGGLE_AUTO_CLICK,
                attribute2: action.payload.email
            }));
        } else {
            yield put(resetPasswordFailed(validLinkRes.message));
            yield put(sendActivityTracking({
                eventPage: RESET_PASSWORD_EVENT_PAGE,
                eventType: RESET_PASSWORD_LINK_INVALID,
                attribute1: action.payload.email,
                attribute2: validLinkRes.message
            }));
        }
    } catch (error) {
        console.log(error);
        yield put(resetPasswordFailed(error));
        yield put(sendActivityTracking({
            eventPage: RESET_PASSWORD_EVENT_PAGE,
            eventType: RESET_PASSWORD_LINK_INVALID,
            attribute1: error.message
        }));
    }
}

export function* watchCheckResetPasswordLinkValidity() {
    yield takeLatest(CHECK_RESET_PASWORD_LINK_VALID, checkResetPasswordLinkValidSaga);
}

function* trackingActivitySaga(action) {
    try {
        yield put(sendActivityTracking(action.payload.trackingInfo))
    } catch (error) {
        console.log(error);
    }
}

export function* watchTrackingLoginModal() {
    yield takeLatest(TOGGLE_LOGIN_MODAL, trackingActivitySaga);
}

export function* watchTrackingSignupModal() {
    yield takeLatest(TOGGLE_SIGNUP_MODAL, trackingActivitySaga);
}

export function* watchTrackingForgotPasswordModal() {
    yield takeLatest(TOGGLE_FORGOT_PASSWORD_MODAL, trackingActivitySaga);
}

export function* watchTrackingResetPasswordModal() {
    yield takeLatest(RESET_PASSWORD_TOGGLE, trackingActivitySaga);
}

export function* signupResponselandingPage(redirectUrl, eventPage) {
    const url = `${window.location.origin}${redirectUrl}`;
    yield put(sendActivityTracking({
        eventPage,
        eventType: ET_SIGNUP_SUCCESS_FROM_LANDING_PAGE,
        attribute1: url
    }));
    window.open(url, "_self");
}

function* shareReportAfterSignupLoginSuccess(userInfo, onSuccessRender) {
    const shareUrlObject = yield select(shareUrlObj);
    if (onSuccessRender && onSuccessRender.reportType && shareUrlObject && shareUrlObject.type !== 'shareReportLink') {
        window.open(`${window.location.origin}/downloadreport/${onSuccessRender.reportType}/${onSuccessRender.id}/${userInfo._id}`, onSuccessRender.openIn);
    }
    if (onSuccessRender && shareUrlObject && shareUrlObject.type === 'shareReportLink') {
        const eventPage = onSuccessRender.reportType === 'company' ? COMPANY_PROFILE : EP_DIRECTOR_PROFILE
        const reportUrl = `${window.location.origin}/downloadreport/${onSuccessRender.reportType}/${onSuccessRender.id}/${userInfo._id}`;
        const locationArray = window.location.pathname.split('/');
        const urlToShareEmail = `From Pomanda:\r\n\r\n${locationArray[3]} \r\n\r\n ${reportUrl}`;
        const subjectToShare = `${locationArray[3]} ${onSuccessRender.reportType} Page`;
        yield put(toggleShareUrlModal({
            show: true,
            type: 'report',
            eventPage: eventPage,
            eventType: "",
            urlToShare: reportUrl,
            urlToShareEmail: urlToShareEmail,
            subjectToShare: subjectToShare
        }));
    }
}

export function* watchTrackingNewSignupModal() {
    yield takeLatest(TOGGLE_NEW_SIGNUP_MODAL, trackingActivitySaga);
}

export function* watchTrackingNewLoginModal() {
    yield takeLatest(TOGGLE_NEW_LOGIN_MODAL, trackingActivitySaga);
}

function* createStripeSessionOrShowReport(userInfo, stripeSessionObj, eventPage, valuationRptList) {
    switch (stripeSessionObj.productId) {
        case "percent30OffReport":
        case "oneTimePurchaseCreditReport":
        case "oneCredit":
            if (userInfo && ((userInfo.subscription.active === "yes" && userInfo.subscription.productName === "PomandaPlus")
                || (userInfo?.reportPkgSubscription?.creditCount > 0))) {
                yield put(toggleBuyCreditReportModal({
                    toggleStatus: true,
                    companyId: stripeSessionObj.companyId,
                    companyName: stripeSessionObj.companyName,
                    regAddress: stripeSessionObj.regAddress,
                    eventPage
                }));
            } else if (userInfo.userType === PROSPER_USER_TYPE) {
                yield put(toggleSubmitInvestmentInterestModal({
                    toggleStatus: true,
                    subject: "Contact Us",
                    subHeading: `If you are interested in getting an up to date 
                        Credit Report fill in this form and a member of the Prosper2 
                        team will be in touch.`,
                    heading: "Contact Us",
                    userMessage: "What can we help with?",
                    descriptionLabel: "Get in touch",
                    mailRecipient: "CONTACT_US_EMAIL_ADDRESS",
                    eventPage: EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
                    eventType: ET_SUBMIT_INVESTMENT_INTEREST_BTN,
                    companyId: stripeSessionObj.companyId
                }))
            } else {
                yield put(createStripeSession(stripeSessionObj));
            }
            break;
        case "pomandaPlusMonthly":
        case "pomandaPlusYearly":
        case "pomandaPlusOneDay":
            if (userInfo && userInfo.subscription.active === "no" && userInfo.subscription.productName === "PomandaPlus") {
                yield put(createStripeSession(stripeSessionObj));
            }
            break;
        case "oneTime5CreditReportPkg":
        case "oneTime20CreditReportPkg":
        case "oneTime100CreditReportPkg":
        case "oneTimeInfiniteCreditReportPkg":
            if (userInfo && userInfo.subscription.active === "no" && userInfo.subscription.productName === "PomandaPlus") {
                yield put(createStripeSession(stripeSessionObj));
            }
            break;
        case "oneTimePurchaseCompanyReport":
            if (userInfo && userInfo.subscription.active === "yes" && userInfo.subscription.productName === "PomandaPlus") {
                window.open(`${window.location.origin}/downloadreport/company/${stripeSessionObj.companyId}/${userInfo._id}`, "_blank");
            } else {
                yield put(createStripeSession(stripeSessionObj));
            }
            break;
        case "oneTime5ValuationReportPkg":
        case "oneTime20ValuationReportPkg":
        case "oneTimeEnterpriseValuationReportPkg":
        case "oneTime5contactDetailsCreditPkg":
        case "oneTime100contactDetailsCreditPkg":
        case "oneTime500contactDetailsCreditPkg":
        case "oneTime1000contactDetailsCreditPkg":
        case "oneTime5000contactDetailsCreditPkg":
        case "oneTime10000contactDetailsCreditPkg":                    
            yield put(createStripeSession(stripeSessionObj));
            break
        case "oneTime1ValuationReportPkg":
            const { valuationReportMappingDocs = [] } = valuationRptList;
            const valuationReportList = valuationReportMappingDocs.length > 0 ? 
                valuationReportMappingDocs.filter((report) => report.companyId === stripeSessionObj?.companyId)
                : [];
            if (!stripeSessionObj?.companyId || (valuationReportList?.length <= 0 && userInfo.valuationReportPkgSubscription?.valuationRpCount <= 0) || 
                (valuationReportList[0]?.status !== "active" && valuationReportList[0]?.remainingDays <= 0)) {
                yield put(createStripeSession(stripeSessionObj));
            }
            break
        case "businessPlanOnboardPkg":
        case "businessPlanOnboardDiscountPkg":
            const {
                companyExistingStatus, companyName, companyAddress,
                postCode, companyId, industryList, componentId,
                uniqueId, industryDescription, customerDesc, location, startdate, salesTarget,
                tradingName, keyPeople, trad, country
            } = stripeSessionObj.saveBusinessPlanObj
            const businessPlanId = stripeSessionObj?.saveBusinessPlanObj?.businessPlanId || stripeSessionObj.businessPlanId
            const newUniqueId = `pom_${new Date().getTime()}${Math.floor(Math.random() * 10)}`;
            const renderedUniqueId = uniqueId || newUniqueId;
            const response = yield call(saveOrUpdateBusinessPlanOnboardApi, {
                companyExistingStatus, companyName, companyAddress,
                postCode, companyId, industryList, componentId,
                locationList: trad, uniqueId: renderedUniqueId, industryDescription, businessPlanId, 
                customerDesc, location, startdate, salesTarget, tradingName, keyPeople, country
            })
            if (response.businessPlanId) {
                stripeSessionObj.businessPlanId = response?.businessPlanId
                stripeSessionObj.href = `${window.location.origin}/businessplan/${response.uniqueId}/summary`
                yield put(createStripeSession(stripeSessionObj));
            }
            break
        default:

    }
}
