import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { SET_PRODUCT_PAGE_SCREEN, HANDLE_PRODUCT_PLAN_BUTTON_CLICK } from "../redux/actions/actionConstants";
import {
    sendActivityTracking, setLoading
} from "../redux/actions/commonAction"
import products from '../components/ProductPage/Products'
import { setProductPageScreenSuccess } from "../redux/actions/productAction";
import {
    BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT,
    DOWNLOAD_REPORT, DOWNLOAD_REPORT_MSG, SR_ACCOUNT_PAGE,
    SR_CREDIT_REPORT, SR_SIGNUP_BTN_CLICK_HEADER, SR_SIGNUP_BTN_CLICK_LOGIN_MODAL,
    SR_SIGNUP_BTN_CLICK_MBL_MENU,
    SR_SUBMIT_INTEREST_BTN_CLICK
} from "../common/subscriptionConfigConst";
import {
    EP_SIGNUP_CTA_CLICK, EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
    ET_SUBMIT_INVESTMENT_INTEREST_BTN,
    ET_SUBMIT_INVESTMENT_INTEREST_SUBMIT_BTN, LOGIN_EVENT_PAGE, LOGIN_TOGGLE_ON_EVENT_TYPE, SIGNUP_EVENT_PAGE,
    SIGNUP_TOGGLE_ON_EVENT_TYPE
} from "../common/trackingConstants";
import { isBrowser } from "../common/config";
import { createStripeSession } from "../redux/actions/subscriptionAction";
import { toggleLoginModal, toggleSignupModal } from "../redux/actions/loginSignupAction";
import { CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS, TOGGLE_LOGIN_MODAL_ON_SUCCESS, TOGGLE_SIGNUP_MODAL_ON_SUCCESS } from "../common/loginSignupSuccessConstants";
import { callCreditReportApiAction, toggleCRDownloadLoadingModal, toggleSubmitInvestmentInterestModal } from "../redux/actions/companyAction";
import localStorageApi from '../api/localStorageApi';
import { updateUserFreeCRCountAction, updateUserReportCredits } from "../redux/actions/userAccountAction";
import { saveOrUpdateBusinessPlanOnboardApi } from "../api/businessPlanApi";
import { updateBusinessPlansCreditApi } from "../api/userAccountApi";

const planValues = (state) => state.subscription.planDetailValues
const userInfo = (state) => state.userProfile.userInfo


function* setProductPageScreenSaga(action) {
    try {
        const planDetailsValues = yield select(planValues);
        const user = yield select(userInfo);
        const token = isBrowser && (yield call(localStorageApi.getUserAuthToken))
        const {
            PomandaPlus = {},
            oneTimePurchase = {},
            PomandaPlusYearly = {}
        } = planDetailsValues;
        let productObj = {};

        const btnDisableStatus = user && user.subscription ? user.subscription.productName === "PomandaPlus" &&
            user.subscription.active === "yes" ? { disabled: true } : { disabled: false } : {};
        if (user && user.subscription && user.subscription.productName === "PomandaPlus" &&
            user.subscription.active === "yes") {
            if (user.subscription.interval === 'day') {
                products.pomandaPlusMonthly.btn.text = "Subscribe Now";
                products.pomandaPlusYearly.btn.text = "Subscribe Now";
                products.pomandaPlusOneDay.btn.text = "Subscribed";
            } else {
                products.pomandaPlusMonthly.btn.text = "Subscribed";
                products.pomandaPlusYearly.btn.text = "Subscribed";
                products.pomandaPlusOneDay.btn.text = "Subscribed";
            }
        }
        switch (action.payload.screenObj.serviceReq) {
            case SR_ACCOUNT_PAGE:
                productObj.product1 = {
                    type: "periodic",
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlusMonthly,
                        trialPeriod: PomandaPlus.freeTrailDays ? PomandaPlus.freeTrailDays : products.pomandaPlusMonthly.trialPeriod,
                        price: {
                            ...products.pomandaPlusMonthly.price,
                            value: PomandaPlus.planAmount ? PomandaPlus.planAmount : products.pomandaPlusMonthly.price.value
                        },
                        btn: products.pomandaPlusYearly.getBtnData(PomandaPlus?.freeTrailDays,user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlusYearly,
                        trialPeriod: PomandaPlusYearly.freeTrailDays ? PomandaPlusYearly.freeTrailDays : products.pomandaPlusYearly.trialPeriod,
                        price: {
                            ...products.pomandaPlusYearly.price,
                            // value: PomandaPlusYearly.planAmount ? PomandaPlusYearly.planAmount : products.pomandaPlusYearly.price.value
                        },
                        btn: products.pomandaPlusYearly.getBtnData(PomandaPlusYearly?.freeTrailDays,user?.subscription)
                    }
                };
                productObj.product2 = {
                    ...products.pomandaPlusOneDay,
                    btn: { ...products.pomandaPlusOneDay.btn, ...btnDisableStatus }
                },
                productObj.product3 = {
                    type: 'periodic',
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlus5kMonthly,
                        price: {
                            ...products.pomandaPlus5kMonthly.price
                        },
                        btn: products.pomandaPlus5kMonthly.getBtnData(user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlus5kYearly,
                        price: {
                            ...products.pomandaPlus5kYearly.price
                        },
                        btn: {
                            ...products.pomandaPlus5kYearly.getBtnData(user?.subscription)
                        }
                    }
                }
                productObj.free = {
                    ...products.free,
                    btn: {...products.free.btn}
                }
                productObj.enterprise = {
                    ...products.enterprise,
                    btn: {...products.enterprise.btn}
                }
                productObj.pomandaPlus5k = {
                    ...products.pomandaPlus5k,
                    btn: {...products.pomandaPlus5k.getBtnData(user?.subscription)}
                }
                break;
            case SR_SIGNUP_BTN_CLICK_LOGIN_MODAL:
            case SR_SIGNUP_BTN_CLICK_HEADER:
            case SR_SIGNUP_BTN_CLICK_MBL_MENU:
            case SR_SUBMIT_INTEREST_BTN_CLICK:

                productObj.product1 = {
                    type: "periodic",
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlusMonthly,
                        trialPeriod: PomandaPlus.freeTrailDays ? PomandaPlus.freeTrailDays : products.pomandaPlusMonthly.trialPeriod,
                        price: {
                            ...products.pomandaPlusMonthly.price,
                            value: PomandaPlus.planAmount ? PomandaPlus.planAmount : products.pomandaPlusMonthly.price.value
                        },
                        btn: products.pomandaPlusYearly.getBtnData(PomandaPlus?.freeTrailDays,user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlusYearly,
                        trialPeriod: PomandaPlusYearly.freeTrailDays ? PomandaPlusYearly.freeTrailDays : products.pomandaPlusYearly.trialPeriod,
                        price: {
                            ...products.pomandaPlusYearly.price,
                            // value: PomandaPlusYearly.planAmount ? PomandaPlusYearly.planAmount : products.pomandaPlusYearly.price.value
                        },
                        btn: products.pomandaPlusYearly.getBtnData(PomandaPlusYearly?.freeTrailDays,user?.subscription)
                    }
                };
                productObj.product2 = {
                    ...products.pomandaPlusOneDay,
                    btn: { ...products.pomandaPlusOneDay.btn, ...btnDisableStatus }
                };
                productObj.product3 = {
                    type: 'periodic',
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlus5kMonthly,
                        price: {
                            ...products.pomandaPlus5kMonthly.price
                        },
                        btn: products.pomandaPlus5kMonthly.getBtnData(user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlus5kYearly,
                        price: {
                            ...products.pomandaPlus5kYearly.price
                        },
                        btn: {
                            ...products.pomandaPlus5kYearly.getBtnData(user?.subscription)
                        }
                    }
                };
                productObj.free = {
                    ...products.free,
                    btn: {...products.free.btn}
                }
                productObj.enterprise = {
                    ...products.enterprise,
                    btn: {...products.enterprise.btn}
                }
                productObj.pomandaPlus5k = {
                    ...products.pomandaPlus5k,
                    btn: {...products.pomandaPlus5k.getBtnData(user?.subscription)}
                }
                yield put(sendActivityTracking({
                    eventPage: EP_SIGNUP_CTA_CLICK,
                    eventType: `ET_${action.payload.screenObj.serviceReq}`
                }))
                if (!token || token === "" || token === null) {
                    yield put(toggleSignupModal(
                        { status: true },
                        {
                            eventPage: SIGNUP_EVENT_PAGE,
                            eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                            // ...trkAttribute
                        },
                        {}
                    ))
                }
                break;

            case SR_CREDIT_REPORT:
                productObj.product1 = {
                    type: "periodic",
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlusMonthly,
                        trialPeriod: PomandaPlus.freeTrailDays ? PomandaPlus.freeTrailDays : products.pomandaPlusMonthly.trialPeriod,
                        price: {
                            ...products.pomandaPlusMonthly.price,
                            value: PomandaPlus.planAmount ? PomandaPlus.planAmount : products.pomandaPlusMonthly.price.value
                        },
                        btn: products.pomandaPlusYearly.getBtnData(PomandaPlus?.freeTrailDays,user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlusYearly,
                        trialPeriod: PomandaPlusYearly.freeTrailDays ? PomandaPlusYearly.freeTrailDays : products.pomandaPlusYearly.trialPeriod,
                        price: {
                            ...products.pomandaPlusYearly.price,
                            // value: PomandaPlusYearly.planAmount ? PomandaPlusYearly.planAmount : products.pomandaPlusYearly.price.value
                        },
                        btn: products.pomandaPlusYearly.getBtnData(PomandaPlusYearly?.freeTrailDays,user?.subscription)
                    }
                };
                productObj.product3 = {
                    type: 'periodic',
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlus5kMonthly,
                        price: {
                            ...products.pomandaPlus5kMonthly.price
                        },
                        btn: products.pomandaPlus5kMonthly.getBtnData(user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlus5kYearly,
                        price: {
                            ...products.pomandaPlus5kYearly.price
                        },
                        btn: {
                            ...products.pomandaPlus5kYearly.getBtnData(user?.subscription)
                        }
                    }
                };
                productObj.product2 = {
                    ...products.oneTimePurchaseCreditReport,
                    trialPeriod: oneTimePurchase.freeTrailDays ? oneTimePurchase.freeTrailDays : products.oneTimePurchaseCreditReport.trialPeriod,
                    price: {
                        ...products.oneTimePurchaseCreditReport.price,
                        value: oneTimePurchase.planAmount ? oneTimePurchase.planAmount : products.oneTimePurchaseCreditReport.price.value
                    },
                    smallDescription: ()=>products.oneTimePurchaseCreditReport.smallDescription(action.payload.screenObj.companyName)
                };
                productObj.free = {
                    ...products.free,
                    btn: {...products.free.btn}
                }
                productObj.enterprise = {
                    ...products.enterprise,
                    btn: {...products.enterprise.btn}
                }
                productObj.pomandaPlus5k = {
                    ...products.pomandaPlus5k,
                    btn: {...products.pomandaPlus5k.getBtnData(user?.subscription)}
                }
                break;

            default:
                productObj.product1 = {
                    type: "periodic",
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlusMonthly,
                        trialPeriod: PomandaPlus.freeTrailDays ? PomandaPlus.freeTrailDays : products.pomandaPlusMonthly.trialPeriod,
                        price: {
                            ...products.pomandaPlusMonthly.price
                        },
                        btn: products.pomandaPlusMonthly.getBtnData(PomandaPlus?.freeTrailDays,user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlusYearly,
                        trialPeriod: PomandaPlusYearly.freeTrailDays ? PomandaPlusYearly.freeTrailDays : products.pomandaPlusYearly.trialPeriod,
                        price: {
                            ...products.pomandaPlusYearly.price,
                            // value: PomandaPlusYearly.planAmount ? PomandaPlusYearly.planAmount : products.pomandaPlusYearly.price.value
                        },
                        btn: products.pomandaPlusYearly.getBtnData(PomandaPlusYearly?.freeTrailDays,user?.subscription)
                    }
                };
                productObj.product3 = {
                    type: 'periodic',
                    isPeriodic: true,
                    monthly: {
                        ...products.pomandaPlus5kMonthly,
                        price: {
                            ...products.pomandaPlus5kMonthly.price
                        },
                        btn: products.pomandaPlus5kMonthly.getBtnData(user?.subscription)
                    },
                    yearly: {
                        ...products.pomandaPlus5kYearly,
                        price: {
                            ...products.pomandaPlus5kYearly.price
                        },
                        btn: {
                            ...products.pomandaPlus5kYearly.getBtnData(user?.subscription)
                        }
                    }
                };
                productObj.product2 = {
                    ...products.pomandaPlusOneDay,
                    btn: { ...products.pomandaPlusOneDay.btn, ...btnDisableStatus }
                }
                productObj.free = {
                    ...products.free,
                    btn: {...products.free.btn}
                }
                productObj.enterprise = {
                    ...products.enterprise,
                    btn: {...products.enterprise.btn}
                }
                productObj.pomandaPlus5k = {
                    ...products.pomandaPlus5k,
                    btn: {...products.pomandaPlus5k.getBtnData(user?.subscription)}
                }
                break;
        }
        yield put(setProductPageScreenSuccess(productObj))
    } catch (error) {
        console.log('inside setProductPageScreenSaga ', error);
    }
}

export function* watchSetProductPageScreenSaga() {
    yield takeLatest(SET_PRODUCT_PAGE_SCREEN, setProductPageScreenSaga);
}

function* handleProductPlanButtonClickSaga(action) {
    try {
        const { 
            productId, saveBusinessPlanObj, remainingReports=0, ...productObj 
        } = action.payload.productObj;
        let token = isBrowser && (yield call(localStorageApi.getUserAuthToken))
        const userInfoObj = yield select(userInfo);
        switch (productId) {
            case "pomandaPlusMonthly":
            case "pomandaPlusYearly":
            case "oneTimePurchaseCreditReport":
            case "percent30OffReport":
            case "pomandaPlusOneDay":
            case "oneTimePurchaseCompanyReport":
            case "oneTime5CreditReportPkg":
            case "oneTime20CreditReportPkg":
            case "oneTime100CreditReportPkg":
            case "oneTimeInfiniteCreditReportPkg":
            case "oneTime1ValuationReportPkg":
            case "oneTime5ValuationReportPkg":
            case "oneTime20ValuationReportPkg":
            case "oneTimeExtraCSVDownloadsPkg":
            case "oneTime5contactDetailsCreditPkg":
            case "oneTime100contactDetailsCreditPkg":
            case "oneTime500contactDetailsCreditPkg":
            case "oneTime1000contactDetailsCreditPkg":
            case "oneTime5000contactDetailsCreditPkg":
            case "oneTime10000contactDetailsCreditPkg":
                
                if (token && token !== "") {
                    yield put(createStripeSession(productObj));
                } else {
                    yield put(toggleSignupModal(
                        { status: true, signUpModelData: action.payload?.productObj?.signUpModelData },
                        {
                            eventPage: SIGNUP_EVENT_PAGE,
                            eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                            // ...trkAttribute
                        }, {
                        toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
                        serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                        eventPage: action.payload?.eventPage,
                        stripeSessionObj: { ...action.payload.productObj }
                    }))
                }
                break;
            case "oneTimeEnterpriseValuationReportPkg":
                if (token && token !== "") {
                    yield put(createStripeSession(productObj));
                } else {
                    if (productObj.userType === "basic") {
                        yield put(toggleLoginModal(
                            { status: true },
                            {
                                eventPage: LOGIN_EVENT_PAGE,
                                eventType: LOGIN_TOGGLE_ON_EVENT_TYPE,
                                // ...trkAttribute
                            }, {
                            toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS,
                            serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                            eventPage: action.payload?.eventPage,
                            stripeSessionObj: { ...action.payload.productObj }
                        }))
                    } else {
                        yield put(toggleSignupModal(
                            { status: true },
                            {
                                eventPage: SIGNUP_EVENT_PAGE,
                                eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                                // ...trkAttribute
                            }, {
                            toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
                            serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                            eventPage: action.payload?.eventPage,
                            stripeSessionObj: { ...action.payload.productObj }
                        }))
                    }
                }
                break;
            case "enterprise":
            case "whiteLabelEnquiry":
            case "subscriptionAssistanceRequest":
            case "valuationSubscription":
                const subHeading = `Get in touch with one of our team to discuss how we can create a subscription package that meets the needs of your organisation.`;
                const userMessage = `Please contact me to discuss options for teams and larger organisations...`;
                const subject = productId === "enterprise" ? "Enterprise Sales" : productId === "whiteLabelEnquiry" ? "White Label Inquiry" : productId === "valuationSubscription" ? "Valuation Subscription Inquiry" : "Subscription Assistance Request";
                const heading = productId === "enterprise" ? "Enterprise Sales" : productId === "whiteLabelEnquiry" ? "White Label Inquiry" : productId === "valuationSubscription" ? "Valuation Subscription Inquiry" : "Subscription Assistance Request";
                yield put(toggleSubmitInvestmentInterestModal({
                    toggleStatus: true,
                    companyId: productObj?.companyId,
                    subject,
                    subHeading,
                    heading,
                    userMessage,
                    descriptionLabel: "Describe your requirements",
                    mailRecipient: "SUBMIT_INTEREST_PRODUCT_EMAIL_ADDRESS",
                    eventPage: EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
                    eventType: ET_SUBMIT_INVESTMENT_INTEREST_SUBMIT_BTN
                }));
                break;
            case "pomandaPlusFreeReport":
                yield all([
                    put(callCreditReportApiAction({ ...productObj, userId: userInfoObj._id })),
                    put(toggleCRDownloadLoadingModal({
                        loaderImg: true,
                        heading: DOWNLOAD_REPORT,
                        subHeading: DOWNLOAD_REPORT_MSG,
                        toggleStatus: true,
                        showContinueBtn: true
                    })),
                    put(updateUserFreeCRCountAction(userInfoObj.subscription.freeCR - 1))
                ]);
                break;
            case "oneCredit":
                yield all([
                    put(callCreditReportApiAction({ ...productObj, userId: userInfoObj._id })),
                    put(toggleCRDownloadLoadingModal({
                        loaderImg: true,
                        heading: DOWNLOAD_REPORT,
                        subHeading: DOWNLOAD_REPORT_MSG,
                        toggleStatus: true,
                        showContinueBtn: true
                    })),
                    put(updateUserReportCredits(userInfoObj.reportPkgSubscription.creditCount - 1))
                ]);
                break;
            case "businessPlanOnboardPkg":
            case "businessPlanOnboardDiscountPkg": {
                    const {
                        companyExistingStatus, companyName, companyAddress, businessPlanId,
                        postCode, companyId, industryList, componentId, industryDescription, customerDesc,location,
                        startdate, salesTarget, tradingName, keyPeople, trad, userType, country
                    } = saveBusinessPlanObj;
                    const newUniqueId = `pom_${new Date().getTime()}${Math.floor(Math.random() * 10)}`;
                    const renderedUniqueId = saveBusinessPlanObj.uniqueId || newUniqueId;
                    const response = yield call(saveOrUpdateBusinessPlanOnboardApi, {
                        companyExistingStatus, companyName, companyAddress,
                        postCode, companyId, industryList, componentId,
                        locationList: trad, businessPlanId, uniqueId: renderedUniqueId, industryDescription,
                        salesTarget, location, startdate, customerDesc, tradingName, keyPeople,
                        status: "inactive", userType, country
                    });
                    if (!response.error) {
                        const bussPlanUrl = `${window.location.origin}/businessplan/${response.uniqueId}/summary?isSuccess=true&email=${userInfoObj?.email}&planSelected=${productId}&serviceRequested=${BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT}&businessPlanId=${response?.businessPlanId || productObj?.businessPlanId}&uniqueId=${response?.uniqueId}&period=BUSINESS_PLAN_ONBOARD_PKG`
                        window.open(bussPlanUrl, "_self");
                    }
                // commented as we are allowing logged in user for purchase flow by handling login scenario in business plan onboard component.
                // else {
                //     yield put(toggleSignupModal(
                //         { status: true },
                //         {
                //             eventPage: SIGNUP_EVENT_PAGE,
                //             eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                //             // ...trkAttribute
                //         }, {
                //         toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
                //         serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                //         eventPage: action.payload?.eventPage,
                //         stripeSessionObj: { ...action.payload.productObj }
                //     }))
                // }
                break;
            }
            case "prosperGetCredits": 
                yield put(toggleSubmitInvestmentInterestModal({
                    toggleStatus: true,
                    subject: "Contact Us",
                    subHeading: `If you are interested in getting an up to date 
                        Credit Report fill in this form and a member of the Prosper2 
                        team will be in touch.`,
                    heading: "Contact Us",
                    userMessage: "What can we help with?",
                    descriptionLabel: "Get in touch",
                    mailRecipient: "CONTACT_US_EMAIL_ADDRESS",
                    eventPage: EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
                    eventType: ET_SUBMIT_INVESTMENT_INTEREST_BTN,
                    companyId: productObj.companyId
                }))
            default:
            // code block
        }
    } catch (error) {
        console.log('inside handleProductPlanButtonClickSaga ', error);
    }
}

export function* watchHandleProductPlanButtonClickSaga() {
    yield takeLatest(HANDLE_PRODUCT_PLAN_BUTTON_CLICK, handleProductPlanButtonClickSaga);
}
