import React, { Component } from "react";
import browser from 'browser-detect';
import localStorageApi from './api/localStorageApi';
import analyticsApi from './api/analyticsApi';
import { connect } from 'react-redux';
import { getUserProfileRequest } from './redux/actions/userAccountAction';
import {
  callOnloadApi, setScreenWidthHeight, checkStorageAndSendTrkDataAction,
  callTrackerAPIAction, sendActivityTracking, setLoading, setConfirmationModal, setAccessTokenStatus,
  //  setChoosePackageModalData
} from './redux/actions/commonAction';
import {
  handlePaymentSuccessErrorAction, resetStripeSessionReturnDataAction
} from './redux/actions/subscriptionAction';
import {
  resetIndustryMixChanges, resetIndustryMixChangesFailed,
  saveIndustryMixChanges, saveIndustryMixChangesFailed,
  setIndustryMixLoader, setIndustryMixUpdateModal, setIndustryMixUpdateSuccess,
  toggleCRDownloadLoadingModal, toggleBuyCreditReportModal, setUpdatedMixIndustries, noFinancialValuationModal,
  toggleContactDetailsModal,
} from './redux/actions/companyAction';
import { STRIPE_PUBLIC_KEY, isBrowser, RESET_INDUSTRY_MIX_LOADING_MSG, PROSPER_USER_TYPE} from './common/config';
import screenProperties from './common/screenProperties.json';
import externalLib from './common/externalLib'
import { renderRoutes } from 'react-router-config';
import './App.css'
import './css_lib/openSansFont.css'
import 'flag-icon-css/css/flag-icon.css'

import loadable from '@loadable/component'
import { toggleLoginModal, toggleSignupModal } from "./redux/actions/loginSignupAction";
import {
  ET_CH_EMAIL_REDIRECT, LOGIN_EVENT_PAGE, LOGIN_TOGGLE_ON_EVENT_TYPE, TOGGLE_AUTO_CLICK,
  ET_COMPANY_REPORT_LOGIN_MODAL, SIGNUP_EVENT_PAGE, SIGNUP_TOGGLE_ON_EVENT_TYPE, EP_HOMEPAGE,
  COMPANY_PROFILE, ET_COMPANY_REPORT_PDF_REDIRECT, ET_BUY_CREDIT_REPORT_CLICK,
  ET_CREDIT_REPORT_CLICK, EP_DIRECTOR_PROFILE, ET_DIRECTOR_REPORT_PDF_REDIRECT,
  CREATE_WATCHLIST_MODAL_CANCEL_BTN_CLICK,
  CREATE_WATCHLIST_MODAL_CLOSE_BTN_CLICK,
  ET_CONTACT_DETAILS_MODAL_BTN_CLICK,
  CONTACT_CONFIRMATION_MODAL_CLOSE_BTN_CLICK
} from "./common/trackingConstants";
import { TOGGLE_LOGIN_MODAL_AND_REDIRECT, TOGGLE_LOGIN_MODAL_ON_SUCCESS, SHARE_REPORT_URL, TOGGLE_SIGNUP_MODAL_AND_REDIRECT, TOGGLE_SIGNUP_MODAL_ON_SUCCESS } from "./common/loginSignupSuccessConstants";
const SubmitInterestModal = loadable(() => import("./components/CompanyProfile/FundingInvestmentInterest/SubmitInterestModal/SubmitInterestModal"))
const LoadingComponent = loadable(() => import("./components/LoadingComponent/LoadingComponent"));
import util from './common/utils';
import { BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT, H_SUB_FREE_CREDIT_REPORT, SH_DISCOUNTED_CREDIT_REPORT, SH_ONE_TIME_CREDIT_REPORT, SR_CONTACT_DETAILS_CREDIT_PKG_PURCHASE, SR_CREDIT_REPORT } from './common/subscriptionConfigConst';
import { toggleContentShareModal, toggleSeemoreModal } from './redux/actions/commonAction';
import { CREDIT_REPORT_OF_DISSOLVED_COMPANY } from './common/messageModalConstants';
import { toggleSingleProductModal } from "./redux/actions/productAction";
import { getIndustryProfileSearchResult } from "./redux/actions/industryProfileAction";
// import ChoosePackageModal from "./components/ProductPage/ChoosePackageModal/ChoosePackageModal";
import { getLocationProfileSearchRequest } from "./redux/actions/locationProfileAction";
const CookieComponent = loadable(() => import("./components/CookieComponent/CookieComponent"));
const Header = loadable(() => import('./components/HeaderComponent/HeaderComponent'));
const MessageModal = loadable(() => import("./components/common/MessageModal/MessageModal"));
const AddIndustryMix = loadable(() => import("./components/CompanyProfile/IndustryMix/IndustryMix"),
{ resolveComponent: (components) => components.AddIndustryMix });
const IndustryMixLoader = loadable(() => import("./components/CompanyProfile/IndustryMix/IndustryMix"),
{ resolveComponent: (components) => components.IndustryMixLoader });
const IndustryMixUpdate = loadable(() => import("./components/CompanyProfile/IndustryMix/IndustryMix"),
  { resolveComponent: (components) => components.IndustryMixUpdate });
import isEqual from 'lodash.isequal';
import { getIndustryFilterDataRequest, setFilterRestrictionModal, toggleFeatureLockStatus } from "./redux/actions/searchAction";
import { setBusinessPlanOnboardObj } from "./redux/actions/businessPlanAction";
import { getIndustryDetails, setBussTempMainPageData } from "./redux/actions/businessPlanTemplateAction";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import { setAddWatchListModalToggleStatus, setCreateWatchListModalToggleStatus, setUploadCsvToWatchListModalToggleStatus } from "./redux/actions/watchListAction";
const YellowBgButton = loadable(() => import("./components/BusinessPlanTemplate/BusinessPlanComponents/YellowBgButton/YellowBgButton"))
const YellowBorderButton = loadable(() => import("./components/BusinessPlanTemplate/BusinessPlanComponents/YellowBorderButton/YellowBorderButton"))
const WatchListUploadCsvModal = loadable(() => import("./components/WatchListModals/WatchListUploadCsvModal/WatchListUploadCsvModal"))
const ConfirmationModal = loadable(() => import("./components/common/confirmationModal/ConfirmationModal"));
const LoginModal = loadable(() => import('./components/LoginPage/LoginModal'));
const SignUpModal = loadable(() => import('./components/SignUpModal/SignUpModal'));
const ContentShareModal = loadable(() => import('./components/ContentShareModal/ContentShareModal'));
const ForgotPasswordModal = loadable(() => import('./components/LoginPage/ForgotPassword/ForgotPasswordModal'));
const ShareUrlModal = loadable(() => import('./components/ShareUrlModal/ShareUrlModal'));
const CrDownloadLoadingModal = loadable(() => import('./components/CRDownloadLoadingModal/CRDownloadLoadingModal'));
// const NewLoginModal = loadable(() => import('./components/NewLoginPage/NewLoginModal'));
// const NewSignUpModal = loadable(() => import('./components/NewSignUpModal/NewSignUpModal'));
const HomepageMenuModal = loadable(() => import('./components/HomePage/HomepageMenuModal/HomepageMenuModal'));
const ResetPasswordModal = loadable(() => import('./components/LoginPage/ResetPassword/ResetPasswordModal'));
const PaymentResponseModal = loadable(() => import('./components/Subscription/PaymentResponse/PaymentResponseModal'));
const SubscriptionComponent = loadable(() => import("./components/Subscription/SubscriptionComponent"));
const SeemoreIndustryModal = loadable(() => import('./components/IndustryProfileComponent/SeemoreIndustryModal/SeemoreIndustryModal'));
import { FilterRestrictionModal } from "./components/PowerSearch/FilterRestrictionModal/FilterRestrictionModal";
const ComingSoon = loadable(() => import('./components/ComingSoon/ComingSoon'));
const BuyCrModal = loadable(() => import("./components/BuyCrModal/BuyCrModal"));
const ShowContactDetailsModal = loadable(() => import('./components/ContactDetails/ShowContactDetailsModal'))
const WatchListCreateEditModal = loadable(() => import("./components/WatchListModals/WatchListCreateEditModal"))
const directorShareButton = { pageShareButton: "Director Profile Page", reportShareButton: "Director Information Report" };
const companyShareButton = { pageShareButton: "Company Profile Page", reportShareButton: "Company Information Report" };
const companyShareButtonMobile = {
  pageShareButtonMobile: "Share Company Profile Page",
  reportShareButtonMobile: "Share Company Information Report", downloadReportMobile: "Download Company Credit Check",
  downloadInformationMobile: "Download Company Information",
  addToWatchList: "Add To Watchlist",
  editToWatchList: "Edit To WatchList"
};
const directorShareButtonMobile = {
  pageShareButtonMobile: "Share Director Profile Page",
  reportShareButtonMobile: "Share Director Information Report", downloadInformationMobile: "Download Director Information"
};

const result = browser();
class App extends Component {

  constructor() {
    super()
    this.state = {
      isPaymentResponseModal: false,
      updatedMixIndustries: [],
      isMixIndustriesFilled: false,
      updatedMixIndustriesCompanyId: "",
      paymentModal: {
        isSuccess: false,
        sessionId: "",
        email: "",
        planSelected: "",
        serviceRequested: "",
        pdfLink: "",
        companyId: "",
        cohId: "",
        companyName: "",
        businessPlanId: "",
        uniqueId:""
      },
      businessPlanIndustryMix: true

    }
  }

  async componentDidMount() {
   analyticsApi.clarityOnload()
    if (window.history.scrollRestoration === 'auto') {
      window.history.scrollRestoration = 'manual';
    }
    const accesstokenCookie = localStorageApi.getCookie('accessToken');
    if (accesstokenCookie && accesstokenCookie.trim().length > 0) {
      const userData = localStorageApi.getUserData() || {}
      userData.accessToken = accesstokenCookie;
      localStorageApi.setUserData(userData)
    }
    //let accessToken = localStorageApi.getUserAuthToken();
    const secondaryColour = this.props?.whiteLabelConfig?.colourScheme?.secondaryColour
    if(secondaryColour){
       this.changeSiteColorWithNewColor()
       this.changeSvgPsuedoClassColorWithNewColor(secondaryColour)
    }
    const primaryColour = this.props?.whiteLabelConfig?.colourScheme?.primaryColour
    if(primaryColour){
       this.changeSiteColorWithNewPrimaryColor()
    }
    //this.checkURLAndUpdateUrlQueryData(accessToken)
    await this.getAndUpdateTrackerData()

    let accessToken = localStorageApi.getUserAuthToken();
   
    if (accessToken) {
      this.props.dispatchGetUserProfile();
    }
    this.checkURLAndUpdateUrlQueryData(accessToken)
    let latestTid = localStorageApi.getTid();
    const url = this.parseUrl()
    const urlTid = url.searchParams.get("tid");

    if (urlTid && urlTid !== latestTid) {
      latestTid = urlTid
    }
    this.props.dispatchCallOnloadApi(latestTid);
    let currentScreenProp = this.getCurrentScreenProperty();
    this.props.dispatchSetScreenWidthHeight({ deviceType: currentScreenProp.deviceType, width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', this.updateWindowDimensions);
    setTimeout(() => {
      this.loadExternalJSAndCSS()
    }, 5000);
    await analyticsApi.gtagInit();
	//show login modal when user tries to land on business template page without login
    if(this.props?.history?.location?.state?.showLoginModal){
        this.props.dispatchToggleLoginModal({status:true},
            {
                eventPage: LOGIN_EVENT_PAGE,
                eventType: LOGIN_TOGGLE_ON_EVENT_TYPE,
            }, {
                toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS
            });
    }
    if(!accessToken){
      this.props.dispatchToggleFeatureLockStatus(true)
    }
    if(typeof window !== "undefined" && localStorageApi.getCookie('pomanda-cookie-consent') === 'true') {
      analyticsApi.grantAllConsent()
    }
  }

  getSessionId=(url)=>{
    let sessionId = url.searchParams.get("session_id")
    if(!sessionId){
      const regexForSessionId = /session_id=([^?&]+)/;
      const match =  window.location.href?.match(regexForSessionId)
      sessionId = match?.[1]
    }
   return sessionId
  }

  checkURLAndUpdateUrlQueryData = (accessToken) => {
    if (window.location.search !== "") {
      let url = this.parseUrl()
      let paymentModal = {};
      let pathname = window.location.pathname;
      const sessionId = this.getSessionId(url);
      const isSuccess = url.searchParams.get("isSuccess")
      const email = url.searchParams.get("email")
      const planSelected = url.searchParams.get("planSelected")
      const serviceRequested = url.searchParams.get("serviceRequested")
      const cohId = url.searchParams.get("cohId")
      const pageType = url.searchParams.get("pagetype")
      const pageName = url.searchParams.get("pagename")
      const urlTid = url.searchParams.get("tid");
      const eid = url.searchParams.get("eid");
      const redirectto = url.searchParams.get("redirectto");
      const period = url.searchParams.get("period");
      const businessPlanId = url.searchParams?.get("businessPlanId");
      const uniqueId = url.searchParams?.get("uniqueId");
      const userId = url.searchParams.get("userId");
      if ((sessionId && isSuccess && email && planSelected && serviceRequested && cohId) || (serviceRequested === BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT)) {
        paymentModal.isSuccess = isSuccess === "true" ? true : false;
        paymentModal.sessionId = sessionId
        paymentModal.email = email;
        paymentModal.planSelected = planSelected;
        paymentModal.serviceRequested = serviceRequested;
        paymentModal.pdfLink = url.searchParams.get("pdfLink");
        paymentModal.companyId = url.searchParams?.get("companyId");
        paymentModal.cohId = cohId;
        paymentModal.period = period;
        paymentModal.businessPlanId = businessPlanId;
        paymentModal.uniqueId = uniqueId;
        paymentModal.userId = userId;
        paymentModal.companyName = pathname && pathname.startsWith("/company") ? decodeURIComponent(pathname.split("/")[3]) : "";
          this.setState({ isPaymentResponseModal: true, paymentModal });
      }
      this.checkAndDispatchSignupLoginModal(accessToken, pageType, pageName, urlTid, eid, redirectto)
    }
  }

  parseUrl = () => {
    let url_string = isBrowser ? window.location.href:""
    return new URL(url_string);
  }
  checkAndDispatchSignupLoginModal = (accessToken, pageType, pageName, urlTid, eid, redirectto) => {
    const statusObj = { status: true }
    let toggleTypeObj = {}
    const trkAttribute = {
      attribute1: "URL_PARAMS",
      attribute2: window.location.href,
      attribute3: urlTid ? urlTid : "",
      attribute4: eid ? window.atob(eid) : "",
      attribute5: redirectto ? redirectto : ""
    }
    if (!accessToken && pageType && pageType.toLowerCase() === "modal" && pageName) {
      switch (pageName.toLowerCase()) {
        case "signup":
          toggleTypeObj = { toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS }
          if (redirectto) {
            toggleTypeObj = {
              toggleType: TOGGLE_SIGNUP_MODAL_AND_REDIRECT,
              url: redirectto
            }
          }
          this.props.dispatchToggleSignupModal(statusObj, {
            eventPage: SIGNUP_EVENT_PAGE,
            eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
            ...trkAttribute
          }, toggleTypeObj)
          break;
        case "login":
          toggleTypeObj = { toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS }
          if (redirectto) {
            toggleTypeObj = {
              toggleType: TOGGLE_LOGIN_MODAL_AND_REDIRECT,
              url: redirectto
            }
          }
          this.props.dispatchToggleLoginModal(statusObj,
            {
              eventPage: LOGIN_EVENT_PAGE,
              eventType: LOGIN_TOGGLE_ON_EVENT_TYPE,
              ...trkAttribute
            }, toggleTypeObj);
          break;
        default:
          break;
      }
    } else {
      if (redirectto) {
        this.props.dispatchSendActivityTracking(
          {
            eventPage: EP_HOMEPAGE,
            eventType: ET_CH_EMAIL_REDIRECT,
            ...trkAttribute
          }
        )
        this.props.history.push(redirectto)

      }
    }
  }
  getAndUpdateTrackerData = async () => {
    let tid = null
    if (window.location.search !== "") {
      let url = this.parseUrl()
      tid = url.searchParams.get("tid");
    }
    let userTrackerObj = await localStorageApi.getUserData();
    if (userTrackerObj) {
      userTrackerObj.language = navigator.language;
      userTrackerObj.browserVersion = result.version;
      tid != null && (userTrackerObj.first_tid = null)
      tid !== null && (userTrackerObj.latest_tid = tid)
      userTrackerObj.tid = tid
    } else {
      userTrackerObj = {
        accessToken: "",
        country: "",
        osVersion: "",
        rememberMe: false,
        os: result.os,
        deviceType: result.mobile ? "Mobile" : "Desktop",
        browser: result.name,
        browserVersion: result.version,
        language: navigator.language,
        first_tid: tid,
        latest_tid: tid,
        tid: tid
      };
    }
    userTrackerObj.pageUrl = window.location.href
    userTrackerObj.referrer = document.referrer
    if (localStorageApi.getCookie('trackerId') && localStorageApi.getCookie('sessionId')) {
      userTrackerObj.trackerId = localStorageApi.getCookie('trackerId')
      userTrackerObj.sessionId = localStorageApi.getCookie('sessionId')
    }
    if (localStorageApi.getCookie('first_tid') && localStorageApi.getCookie('latest_tid')) {
      userTrackerObj.first_tid = JSON.parse(localStorageApi.getCookie('first_tid'))
      userTrackerObj.latest_tid = JSON.parse(localStorageApi.getCookie('latest_tid'))
    }
    analyticsApi.setCustomData("trackerId", userTrackerObj.trackerId)
    if ((localStorageApi.getCookie('accessToken') === '' || localStorageApi.getCookie('accessToken')
      === 'undefined') && (userTrackerObj.accessToken !== '')) {
      userTrackerObj.accessToken = ''
    }
    localStorageApi.setCookie("latest_tid", userTrackerObj.latest_tid)
    localStorageApi.setUserData(userTrackerObj)
    this.props.dispatchCallTrackerAPIAction(userTrackerObj)
  }

  updateWindowDimensions = () => {
    let currentScreenProp = this.getCurrentScreenProperty();
    currentScreenProp && this.props.dispatchSetScreenWidthHeight({
      deviceType: currentScreenProp.deviceType,
      width: window.innerWidth, height: window.outerHeight
    });
  }

  getCurrentScreenProperty = () => {
    return screenProperties.find(screenPropertyObj => {
      if (screenPropertyObj.minWidth <= window.innerWidth &&
        (screenPropertyObj.maxWidth && screenPropertyObj.maxWidth > window.innerWidth)) {
        return true
      } else if (screenPropertyObj.minWidth < window.innerWidth && (!screenPropertyObj.maxWidth)) {
        return true;
      } else {
        return false;
      }
    });
  }
  redirectTOCheckout = (stripeObj) => {
    let { error } = stripeObj.redirectToCheckout(this.props.subscriptionReturnObj)
    console.log(error);
    this.props.dispatchResetStripeSessionReturnDataAction()
  }
  addColorBasedOnDefaultColorShade =(propertyName , colorname="secondaryColour")=>{
    const secondaryColour = this.props?.whiteLabelConfig?.colourScheme?.[colorname]
    document.documentElement.style.setProperty(propertyName, secondaryColour);
  }
  setSvgImageToCssClass = (base64svg) => {
    const styleElement = document.createElement('style');
    const cssRule = `.estimation-icon-style::before { background-image: url(${base64svg}) }`;
    styleElement.appendChild(document.createTextNode(cssRule));
    document.head.appendChild(styleElement);
  }
  changeSiteColorWithNewPrimaryColor = () => {
    this.addColorBasedOnDefaultColorShade('--background-color',"primaryColour")
  }
  changeSiteColorWithNewColor = () => {
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-E7AA41')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-DE9D2C')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-FDE368')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-F5D84B')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-E39812')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-DF8D00')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-F8AF30')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-FFCE78')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-E19D32')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-E2A21F')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-DB850E')
    this.addColorBasedOnDefaultColorShade('--pomanda-selective-color-with-FFD121')
  }

  changeSvgPsuedoClassColorWithNewColor = async(secondaryColour) => {
    let modifiedSvg = ""
       await fetch(`/assets/svgs/thunder_yellow2x.svg`)
       .then((response) => 
         response.text())
       .then(async(svgContent) => {
         svgContent = svgContent?.toString().replace(/#E1A132/g,secondaryColour )
         const imgSrcStart = `data:image/svg+xml;base64,`;
          modifiedSvg = await imgSrcStart.concat(new Buffer.from(svgContent).toString('base64'));
        })
      this.setSvgImageToCssClass(modifiedSvg);
  }
  componentDidUpdate = async (prevProps) => {
    const accesstokenCookie = localStorageApi.getCookie('accessToken') ? true : false;
    if(accesstokenCookie !== this.props.accessTokenStatus){
      this.props.dispatchSetAccessTokenStatus(accesstokenCookie)
    }
    if (this.props.redirectToStripe) {
      if (window.Stripe) {
        const stripeObj = window.Stripe(STRIPE_PUBLIC_KEY)
        this.redirectTOCheckout(stripeObj)
      } else {
        this.createElementAndLoad({ injectPlace: "body", injectType: "script", id: "stripe-js", src: "https://js.stripe.com/v3/", type: "text/javascript", async: true })
        document.querySelector("#stripe-js").addEventListener("load", async () => {
          const stripeObj = window.Stripe(STRIPE_PUBLIC_KEY)
          this.redirectTOCheckout(stripeObj)
        });
      }
    }
    const secondaryColour = this.props?.whiteLabelConfig?.colourScheme?.secondaryColour
    if(secondaryColour&& prevProps?.whiteLabelConfig?.colourScheme?.secondaryColour !== secondaryColour){
       this.changeSiteColorWithNewColor()
       this.changeSvgPsuedoClassColorWithNewColor(secondaryColour)
    }
    const {
      actTrkToken = "",
      companyProfile,
      userInfo = {},
      restrictAccessForProsperUser = false,
      history,
      industryEditFlag,
      industryListForMix,
      businessPlanUpdatedIndustry,
      businessTempIndustryInsightData = {}
    } = this.props;
    // let accessToken = localStorageApi.getUserAuthToken();
    const {
      indMix : businessTempUpdatedIndustries = []
    } = businessTempIndustryInsightData;
    let industryBreakDownList = []
    industryBreakDownList = companyProfile?.industryBreakDownList || this.props.companyValuation?.industryMix || this.props.selectedCompetitorAnalysis?.industryBreakDownList
    const token = localStorageApi.getUserAuthToken();
    if ((prevProps.userInfo?._id !== userInfo?._id) || (!token && prevProps.actTrkToken !== actTrkToken)) {
      this.props.dispatchCheckStorageAndSendTrkDataAction();
      if (userInfo?.userType === PROSPER_USER_TYPE && restrictAccessForProsperUser) {
        history.push("/landingpage/prosper2");
      }
    }

    if ((prevProps.userInfo?._id !== userInfo?._id)) {
      const status = userInfo?.subscription?.active === "no" 
      this.props.dispatchToggleFeatureLockStatus(status)
    } 
    //industry
    this.handleIndustryMix(prevProps, industryBreakDownList)
    if (industryEditFlag && industryListForMix?.length === 0 && this.state.businessPlanIndustryMix) {
      this.props.dispatchGetIndustryFilterDataRequest();
      this.props.dispatchSetIndustryMixLoader({ show: true, message: "Loading industries.." });
      this.setState({ businessPlanIndustryMix: false })
    }
    if (!isEqual(prevProps.businessPlanUpdatedIndustry, businessPlanUpdatedIndustry) && businessPlanUpdatedIndustry?.length <= 5) {
      this.setState({ updatedMixIndustries: businessPlanUpdatedIndustry })
    }
    if(!(isEqual(prevProps.businessTempIndustryInsightData.indMix, businessTempUpdatedIndustries)) && businessTempUpdatedIndustries?.length <= 5) {
      this.setState({ updatedMixIndustries: businessTempUpdatedIndustries })
    }
    if(prevProps.bsuniqueId !== this.props.bsuniqueId && prevProps.bsuniqueId === "") {
      this.checkURLAndUpdateUrlQueryData(token)
    }
  }
  handleIndustryMix = (prevProps, industryBreakDownList) => {
    const shouldIndustryUpdate = (industryBreakDownList?.length > 0 && !this.state.updatedMixIndustries) || (prevProps.companyProfile &&
      prevProps.companyProfile.industryBreakDownList && !isEqual(prevProps.companyProfile.industryBreakDownList, industryBreakDownList)) ||
      (industryBreakDownList && prevProps?.companyValuation?.industryMix && prevProps?.companyValuation?.industryMix?.length !== industryBreakDownList?.length)
      || prevProps?.companyValuation?.industryMix && !isEqual(prevProps.companyValuation.industryMix, industryBreakDownList) 
      || prevProps?.selectedCompetitorAnalysis?.industryBreakDownList && !isEqual(prevProps.selectedCompetitorAnalysis?.industryBreakDownList, industryBreakDownList)
      ? true
      : false;

    if (prevProps?.companyValuation?.industryMix && !isEqual(prevProps?.companyValuation?.industryMix, industryBreakDownList)) {
      this.props.dispatchSetUpdatedMixIndustries([]);
      this.resetErrorSuccessOnIndMixChange(true);
    }
    if (prevProps.companyProfile &&
      prevProps.companyProfile.industryBreakDownList &&
      !isEqual(prevProps.companyProfile.industryBreakDownList, industryBreakDownList)) {
      this.resetErrorSuccessOnIndMixChange(true);
    }

    if ((!this.state.isMixIndustriesFilled && industryBreakDownList?.length > 0) || shouldIndustryUpdate) {

      let updatedMixIndustries = industryBreakDownList?.map(industry => {
        if (typeof industry.value === "number") {
          industry.value = industry.value.toString();
        };

        return ({
          ...industry,
          value: industry.value && industry.value.includes("%") ? industry.value : `${industry.value}%`
        })
      });

      this.setState({
        updatedMixIndustries,
        isMixIndustriesFilled: true
      })
      this.props.dispatchSetUpdatedMixIndustries(updatedMixIndustries);
    }
  }
  handlePaymentModalCloseBtn = () => {
    analyticsApi.onPageUnLoad()
    analyticsApi.onPageLoad(window.location.pathname)
    this.props.dispatchHandlePaymentSuccessErrorAction(this.state.paymentModal)
    this.setState({ isPaymentResponseModal: false}, () => {
      this.props.history.push(window.location.pathname)
    });
  }

  handlePaymentModalFailedBtn = (props) => {
    this.setState({ isPaymentResponseModal: false }, () => {
      props.history.push(window.location.pathname)
    });

  }

  handleDownloadContinueClick = () => {
    this.props.dispatchToggleDownloadLoadingModal({
      loaderImg: false,
      heading: "",
      subHeading: "",
      toggleStatus: false,
      showContinueBtn: false
    });
  }

  handleDownloadOkClick = () => {
    const {
      downloadModalDataObj = {}
    } = this.props;
    const {
      userCrDownloadMappingId = "",
      userReportMappingId = ""
    } = downloadModalDataObj;
    this.props.dispatchToggleDownloadLoadingModal({
      loaderImg: false,
      heading: "",
      subHeading: "",
      toggleStatus: false,
      showContinueBtn: false
    });
    userCrDownloadMappingId && userCrDownloadMappingId !== "" &&
      window.open(`${window.location.origin}/credit-report/${userCrDownloadMappingId}`, "_blank");
      userReportMappingId && 
      window.open(`${window.location.origin}/downloadreport/company/${userReportMappingId}`)

  }

  loadExternalJSAndCSS = () => {

    externalLib.forEach(element => {
      let { isLoadAllowed = true } = element.checkBeforeLoad ? element.checkBeforeLoad() : {}
      isLoadAllowed && this.createElementAndLoad(element)
    });
  }

  createElementAndLoad = (payload) => {
    const injectElement = document.createElement(payload.injectType);
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        injectElement[key] = payload[key];
      }
    }
    document[payload.injectPlace].appendChild(injectElement);
  }

  handleReportClick = () => {
    let id = this.props.userInfo && this.props.userInfo._id;
    const locationArray = window.location.pathname.split('/');
    const pageType = locationArray[1];
    const pnrOrId = locationArray[2];
    const url = `${window.location.origin}/downloadreport/${pageType}/${pnrOrId}/${id}`;
    const { userInfo = {} } = this.props;
    let token = localStorageApi.getUserAuthToken();
    if (token && token !== "" && userInfo?.subscription?.active === "yes" && userInfo?.subscription?.productName === "PomandaPlus") {
      window.open(url, "_blank");
      this.props.dispatchSendActivityTracking({
        eventPage: pageType === 'company' ? COMPANY_PROFILE : EP_DIRECTOR_PROFILE,
        eventType: pageType === 'company' ? ET_COMPANY_REPORT_PDF_REDIRECT : ET_DIRECTOR_REPORT_PDF_REDIRECT,
        attribute1: url,
        attribute2: id
      })
    } else {
      let trackingInfo = {
        eventPage: LOGIN_EVENT_PAGE,
        eventType: LOGIN_TOGGLE_ON_EVENT_TYPE,
        attribute1: TOGGLE_AUTO_CLICK
      }
      this.props.dispatchSendActivityTracking({
        eventPage: pageType === 'company' ? COMPANY_PROFILE : EP_DIRECTOR_PROFILE,
        eventType: ET_COMPANY_REPORT_LOGIN_MODAL,
        attribute1: url,
        attribute2: id
      })
      this.props.dispatchToggleLoginModal({ status: true, type: "autoClickReport" }, trackingInfo, {
        toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS, serviceType: SHARE_REPORT_URL,
        reportType: pageType, id: pnrOrId, openIn: "_blank"
      });
    }
    this.props.dispatchToggleContentShareModal(false);
  }

  handleWatchListBtnClick = () => {
    this.props.dispatchSetAddWatchListModalToggleStatus(true)
    this.props.dispatchToggleContentShareModal(false);
  }

  handleClickOnCreditReportBtn = (buyCreditReport) => {
    const {
      companyProfile = {}
    } = this.props;
    const {
      status = "",
      companyId = "",
      name = "",
      companyDetails = {},
      industryBreakDownList = [],
      scoreCardData = {},
      shareholders = [],
      charges = {}
    } = companyProfile;
    const companyNameCap = name ? name.toString().toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : "";

    if (status === "Dissolved") {
      this.props.dispatchToggleCommonMessageModal({
        toggleStatus: true,
        ...CREDIT_REPORT_OF_DISSOLVED_COMPANY
      })
    } else {
      if (buyCreditReport) {
        this.props.dispatchSendActivityTracking({
          eventPage: COMPANY_PROFILE,
          eventType: ET_BUY_CREDIT_REPORT_CLICK,
          attribute2: util.getCompanyIdFrmProps(this.props)
        });
      } else {
        this.props.dispatchSendActivityTracking({
          eventPage: COMPANY_PROFILE,
          eventType: ET_CREDIT_REPORT_CLICK,
          attribute2: util.getCompanyIdFrmProps(this.props)
        });
      }
      let token = localStorageApi.getUserAuthToken();
      const {
        userInfo = {}
      } = this.props;
      const {
        subscription = {}
      } = userInfo;
      if (token && token !== "" && subscription.productName === "PomandaPlus" && subscription.active === "yes") {
        if (subscription.freeCR > 0) {
          this.props.dispatchToggleSingleProductModal({
            toggleStatus: true,
            productName: "pomandaPlusFreeReport",
            notificationHeading: `${H_SUB_FREE_CREDIT_REPORT}${companyNameCap}`,
            serviceRequested: SR_CREDIT_REPORT,
            companyName: companyNameCap
          });
        } else {
          this.props.dispatchToggleSingleProductModal({
            toggleStatus: true,
            productName: "percent30OffReport",
            notificationHeading: SH_DISCOUNTED_CREDIT_REPORT,
            serviceRequested: SR_CREDIT_REPORT,
            companyName: companyNameCap
          });
        }

      } else {

        this.props.dispatchToggleSingleProductModal({
          toggleStatus: true,
          productName: "oneTimePurchaseCreditReport",
          notificationHeading: SH_ONE_TIME_CREDIT_REPORT,
          serviceRequested: SR_CREDIT_REPORT,
          companyName: companyNameCap,
          showBtnInNotificationBar: true
        });
      }
      this.props.dispatchToggleContentShareModal(false);
    }
  }

  redirectToProductPage = (serviceReq, companyId, name) => {
    const pathname = isBrowser && window.location.pathname
    const href = isBrowser && window.location.href
    const path = companyId ?
      `/product?serviceReq=${serviceReq}&url=${pathname}&href=${href}&companyId=${companyId}&companyName=${name}`
      : `/product?serviceReq=${serviceReq}&url=${pathname}&href=${href}`;
    this.props.history.push(path);
  }

  handleRedirection = () => {
    let path = ''
    const { modalInfo } = this.props.filterRestrictionModal
    const { servReq = '', productSelected = '' } = modalInfo
    const pathname = isBrowser && window.location.pathname
    const href = isBrowser && window.location.href
    if(productSelected === SR_CONTACT_DETAILS_CREDIT_PKG_PURCHASE) {
      path = '/product?id=product-page-rpt-pkg-sec'
      this.props.dispatchShowContactDetailsModal(false)
    } else if (servReq && productSelected) {
      path = `/product?serviceReq=${servReq}&productSelected=${productSelected}&url=${pathname}&href=${href}`;
    } else if(productSelected) {
      path = `/product?service=DEFAULT&productSelected=${productSelected}&url=/powersearch/company&href=${window.location.origin}/product`
    } else if(this.props?.userInfo) {
        if(!this.props?.userInfo?.isVRWhiteLabelEnabled) {
            path = '/solutions/companyvaluation#valuation_report_packages'
        } 
    } else {
      this.redirectToProductPage('DEFAULT')
    }
    path && this.props.history.push(path);
    this.props.dispatchSetFilterRestrictionModal(false)
  }

  handleToggleIndustryProfileModal = (trackingObj, url) => {
    const { seeMoreModalObj = {} } = this.props;
    const { searchEntity = "", eventPage = "" } = seeMoreModalObj;
    if (searchEntity === "industry") {
      this.props.dispatchGetIndustryProfileSearchResult("", "");
    } else {
      this.props.dispatchGetLocationProfileSearchResult("", "")
    }
    url && this.props.history.push(url);
    this.props.dispatchToggleSeeMoreModel({ toggleStatus: false, searchEntity: "" });
    this.props.dispatchSendActivityTracking({
      eventPage,
      eventType: trackingObj.eventType,
      attribute1: trackingObj.attribute1,
      attribute2: url ? url : ""
    });
  }
  // openChoosePackageModal = (toggleStatus, companyId = "", eventCreatedBy = "", eventPage) => {
  //   eventCreatedBy !== "" && this.props.dispatchSendActivityTracking({
  //     eventPage,
  //     eventType: ET_CHOOSE_PACKAGE_MODAL_CLOSE,
  //     attribute1: companyId,
  //     attribute2: eventCreatedBy
  //   });
  //   this.props.dispatchSetChoosePackageModalData({ toggleStatus });
  // }
  //industry
  addIndustryToBreakdown = (industries) => {
    const { industryEditFlag, businessPlanUpdatedIndustry } = this.props;
    const {
      businessTempIndustryEdit = false,
      indMix : businessTempUpdatedIndustries = {}
    } = this.props.businessTempIndustryInsightData;

    let updatedMixIndustries = industryEditFlag ? businessPlanUpdatedIndustry : businessTempIndustryEdit ? businessTempUpdatedIndustries : this.state.updatedMixIndustries;
    updatedMixIndustries = updatedMixIndustries ? updatedMixIndustries : [];
    const newIndustries = industries.filter((industry) => {
      if ((updatedMixIndustries.filter((stateIndustry) => stateIndustry.SIC2007 === industry.SIC2007).length > 0)) {
        return false
      } else {
        return true
      }
    });
    industryEditFlag && this.props.dispatchSetBusinessPlanBasicData({
      industryList: [...updatedMixIndustries, ...newIndustries]
    })
    if(businessTempIndustryEdit) {
      const industryInsightMix = [...updatedMixIndustries, ...newIndustries]
      this.props.dispatchSetBussTempMainPageData({
        parentLabel: "analysis", 
        childLabel: "industryInsight", 
        data: {indMix: industryInsightMix}
      });
      const sicCodes = industryInsightMix.reduce((prevVal, currVal) => prevVal.concat(currVal.SIC2007), []);
      this.props.disaptchGetIndustryDetails(sicCodes);
    }
    this.setState({ updatedMixIndustries: [...updatedMixIndustries, ...newIndustries] 
    },
      () => this.resetErrorSuccessOnIndMixChange());
  }
  handleSaveIndustryMix = (industryMixObj) => {
    const {
      companyProfile = {},
      companyValuation = {},
      industryMixUpdateModal = {},
      selectedCompetitorAnalysis = {},
      userInfo = {}
    } = this.props;
    const name = companyProfile?.name || companyValuation?.name
    const compValuation = companyProfile?.compValuation || companyValuation?.compValuation || {}
    const type = companyValuation?.name ? "valuation" : selectedCompetitorAnalysis?.REG ? "competitorAnalysis" : ""
    // const { name = "", compValuation = {} } = companyProfile;
    const { turnoverValuation = {} } = compValuation;
    const { valuationInNumber = 0 } = turnoverValuation;
    this.props.dispatchSaveIndustryMixChanges({
      ...industryMixObj,
      name, turnover: valuationInNumber, userId: userInfo._id, type: type
    });
  }

  setMixIndustriesFilled = (toggleStatus) => {
    this.setState({ isMixIndustriesFilled: toggleStatus })
  }

  resetErrorSuccessOnIndMixChange = (isIndustryMixChanged = false) => {
    const {
      companyProfile = {},
      industryMixUpdateSuccess = false,
      saveIndustryChangesError = "",
      resetIndustryMixChangesError = ""
    } = this.props;
    const {
      industryBreakDownList = []
    } = companyProfile;
    const newOrignalIndustryBreakDownList = industryBreakDownList.map(industry => { return ({ ...industry, value: industry.value.replace(/%/g, "") }) });
    const newindustryBreakdownList = this.state.updatedMixIndustries.map(industry => { return ({ ...industry, value: industry.value.replace(/%/g, "") }) });
    const isIndustryMixUpdated = !isEqual(newOrignalIndustryBreakDownList, newindustryBreakdownList);
    if (isIndustryMixUpdated || isIndustryMixChanged) {
      industryMixUpdateSuccess && this.props.dispatchSetIndustryMixUpdateSuccess(false);
      saveIndustryChangesError !== "" && this.props.dispatchSaveIndustryMixChangesFailed("");
      resetIndustryMixChangesError !== "" && this.props.dispatchResetIndustryMixChangesFailed("");
    }

  }

  setIndustryBreakdownList = (industries) => {
    this.setState({ updatedMixIndustries: industries }, () => this.resetErrorSuccessOnIndMixChange());
  }

  industryMixConfirmationReset = () => {
    this.props.dispatchSetIndustryMixLoader({ show: true, message: RESET_INDUSTRY_MIX_LOADING_MSG });
    this.setMixIndustriesFilled(false);
    const {
      companyProfile = {},
      industryMixUpdateModal = {},
      companyValuation = {},
      selectedCompetitorAnalysis = {}
    } = this.props;
    const companyId = companyProfile?.companyId || companyValuation?.companyId || selectedCompetitorAnalysis?.REG;
    const name = companyValuation?.name || companyProfile?.name || selectedCompetitorAnalysis?.companyName
    let type = companyValuation?.name ? "valuation" : selectedCompetitorAnalysis?.REG ? "competitorAnalysis" : "";
    let resetType = companyValuation?.name ? "resetIndustryMixValuation" : "";

    this.props.dispatchResetIndustryMixChanges(companyId, name, type, resetType);
    this.props.dispatchSetUpdatedMixIndustries([]);
  }

  setMixIndustriesFilled = (toggleStatus) => {
    this.setState({ isMixIndustriesFilled: toggleStatus })
  }

  toggleIndustryMixConfirmationModal = (toggleStatus) => {
    this.props.dispatchSetConfirmationModal(toggleStatus);
  }

  handleActivityTrackingAndToggleStatus = (toggleStatus, eventType) => {
    this.props.dispatchSetCreateWatchListModalToggleStatus(toggleStatus)
    this.props.dispatchSendActivityTracking({eventPage: "company_profile", eventType})
  }
  handleContactModalToggle = () => {
    this.props.dispatchSendActivityTracking({
      eventPage: this.props.showContactDetailsModal?.companyInfo?.eventPage || "",
      eventType: CONTACT_CONFIRMATION_MODAL_CLOSE_BTN_CLICK
    })
    this.props.dispatchShowContactDetailsModal(false)
  }

  render() {
    const {
      downloadModalDataObj = {},
      shareUrlObj = {},
      contentShareModal = {},
      submitInterestModalStatus = {},
      resetPaswordSuccessMessage = "",
      resetPasswordErrorMessage = "",
      forgotPasswordEmailSuccess = "",
      forgotPasswordEmailError = "",
      loading = {},
      // choosePackageModalData = {},
      companyProfile = {},
      userInfo = {},
      showConfirmationModal = {},
      industryMixLoader = {},
      companyValuation = {},
      industryMixUpdateModal = {},
      seeMoreModalObj = {},
      buyCrModalObj = {},
      selectedCompetitorAnalysis = {},
      history = {},
      isUserAsGooglBot,
      companyProfileError,
      staticContext={},
      toggleCreateModal = false,
      toggleUploadCsvToWatchListModal = false,
      dispatchSendActivityTracking,
      filterRestrictionModal = {}
    } = this.props;
    const {
      name = "",
      companyId = ""
    } = companyProfile;

    // const companyNameCap = name && name !== "" ? name.toString().toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : "";
    const buttonObj = contentShareModal.type === 'company' ? companyShareButton : directorShareButton;
    const buttonObjMobile = contentShareModal.type === 'company' ? companyShareButtonMobile : directorShareButtonMobile;
    const allowConfirmatioModalForPaths = history.location.pathname.startsWith("/company") || history.location.pathname.startsWith("/competitoranalysis");
    const checkCompanyId = companyProfile?.companyId || companyValuation?.companyId || selectedCompetitorAnalysis?.REG;
    const modalStatusForIndustryMix = (checkCompanyId && allowConfirmatioModalForPaths) ? showConfirmationModal : false;
    const cookieStatus = typeof window !== "undefined" && localStorageApi.getCookie('pomanda-cookie-consent') !== 'true'
    const isIndMixEdited = history.location.pathname.endsWith('/valuation') ? this.props.companyValuation.isIndMixEdited : companyProfile.isIndMixEdited 
    const href = `${staticContext?.baseHref ||""}${history.location.pathname}`

    return (
      <div id="appDiv" className={`${loading.status ? "position-relative" : ""} h-100`}>
        {(isUserAsGooglBot||companyProfileError) ? <PageNotFound path={href}/> : <Header />}
        {loading.status && <LoadingComponent dispatchSetLoading={this.props.dispatchSetLoading} timeout={loading.timeout} />}
        {renderRoutes(this.props.route.routes)}
        {this.props.subscriptionModal.subscriptionToggleStatus
          && <SubscriptionComponent
            planDetailValues={this.props.planDetailValues}
          />}
        {this.props.loginSignupToggleStatus && this.props.loginSignupToggleStatus.isLoginModalOpen
          && <LoginModal />}
        {((this.props.loginSignupToggleStatus && this.props.loginSignupToggleStatus.resetPasswordToggle)
          || (resetPaswordSuccessMessage && resetPaswordSuccessMessage !== "")
          || (resetPasswordErrorMessage && resetPasswordErrorMessage !== ""))
          && <ResetPasswordModal />}
        {this.props.menu && this.props.menu.isMenuModalOpen && <HomepageMenuModal />}
        {this.state.isPaymentResponseModal ? <PaymentResponseModal
          isPaymentResponseModal={this.state.isPaymentResponseModal}
          paymentModal={this.state.paymentModal}
          handlePaymentModalCloseBtn={this.handlePaymentModalCloseBtn}
          handlePaymentModalFailedBtn={this.handlePaymentModalFailedBtn}
          bsuniqueIdStore={this.props.bsuniqueId}
        /> : ""}
        {((this.props.loginSignupToggleStatus && this.props.loginSignupToggleStatus.togglePasswordModal)
          || (forgotPasswordEmailError && forgotPasswordEmailError !== "")
          || (forgotPasswordEmailSuccess && forgotPasswordEmailSuccess !== ""))
          && <ForgotPasswordModal />}
        {this.props.loginSignupToggleStatus && this.props.loginSignupToggleStatus.isSignupModalOpen && <SignUpModal />}
        {/* {this.props.loginSignupToggleStatus && this.props.loginSignupToggleStatus.isNewLoginModalOpen
          && <NewLoginModal />} */}
        {/* {this.props.loginSignupToggleStatus && this.props.loginSignupToggleStatus.isNewSignupModalOpen
          && <NewSignUpModal />} */}
        {cookieStatus && <CookieComponent />}
        {downloadModalDataObj && downloadModalDataObj.toggleStatus && <CrDownloadLoadingModal
          downloadModalDataObj={downloadModalDataObj}
          handleContinueClick={this.handleDownloadContinueClick}
          handleOkClick={this.handleDownloadOkClick} />}
        {shareUrlObj && shareUrlObj.show && <ShareUrlModal />}
        {contentShareModal && contentShareModal.openStatus && <ContentShareModal
          contentShareModal={contentShareModal}
          buttonObj={buttonObj}
          buttonObjMobile={buttonObjMobile}
          handleWatchListBtnClick={this.handleWatchListBtnClick}
          handleReportClick={this.handleReportClick}
          handleClickOnCreditReportBtn={this.handleClickOnCreditReportBtn} />}
        {this.props.isComingSoonModalOpen && <ComingSoon />}
        {submitInterestModalStatus && submitInterestModalStatus.toggleStatus
          && <SubmitInterestModal />}
        {seeMoreModalObj && seeMoreModalObj.toggleStatus && <SeemoreIndustryModal
          isSeemoreIndustryOpen={seeMoreModalObj.toggleStatus}
          handleCloseButtonClick={this.handleToggleIndustryProfileModal}
          handleSelectProfile={this.handleToggleIndustryProfileModal}
          seeMoreModalObj={seeMoreModalObj} />}
        {/*{choosePackageModalData && choosePackageModalData.toggleStatus &&
          <ChoosePackageModal
          //   toggle={this.openChoosePackageModal}
          //   companyId={companyId}
          //   companyName={companyNameCap}
          //   redirectToProductPage={this.redirectToProductPage}
          //   handleTracking={this.props.dispatchSendActivityTracking}
          //   choosePackageModalData={choosePackageModalData}
          //   toggleLoginModal={this.props.dispatchToggleLoginModal}
          //   userInfo={userInfo} />} */}
        {this.props.toggleStatus && <MessageModal />}
        {industryMixUpdateModal.toggleStatus &&
          <IndustryMixUpdate
            industryBreakdownList={this.state.updatedMixIndustries}
            orignalIndustryBreakDownList={companyProfile?.industryBreakDownList?.length > 0 ?
              companyProfile?.industryBreakDownList : this.props.companyValuation?.industryMix ? this.props.companyValuation?.industryMix : this.props.selectedCompetitorAnalysis?.industryBreakDownList}
            companyId={companyId || companyValuation?.companyId || this.props.selectedCompetitorAnalysis?.REG}
            industryMixUpdateSuccess={this.props.industryMixUpdateSuccess}
            screenWidth={this.props.screenProperty.width}
            saveIndustryMixChanges={this.handleSaveIndustryMix}
            pageType={companyValuation.companyId ? "valuation" : ""}
            setIndustryBreakdownList={this.setIndustryBreakdownList}
            setMixIndustriesFilled={this.setMixIndustriesFilled}
            saveIndustryChangesError={this.props.saveIndustryChangesError}
            resetIndustryMixChangesError={this.props.resetIndustryMixChangesError}
            industryList={this.props.industryListForMix}
            industryMixUpdateModalObj={this.props.industryMixUpdateModal}
            userSubscription={this.props.userInfo?.subscription}
            redirectToProductPage={this.redirectToProductPage}
            valuationReportMapObj={companyValuation?.valuationReportMapObj}
            isIndustryMixEdited={ isIndMixEdited }
          />
        }
        {this.props.addIndustryMixModal &&
          <AddIndustryMix
            industryCount={this.state.updatedMixIndustries?.length}
            industryList={this.props.industryListForMix}
            addIndustryToBreakdown={this.addIndustryToBreakdown}
            companyId={companyId || companyValuation.companyId}
            type={companyValuation?.companyId ? "valuation" : ""}
            valuationReportMapObj={companyValuation?.valuationReportMapObj}
            industryMixUpdateModalObj={this.props.industryMixUpdateModal}
          />
        }
        {modalStatusForIndustryMix && <ConfirmationModal
          showConfirmationModal={modalStatusForIndustryMix}
          heading="Are you sure?"
          subHeading="You are about to reset the Industry Breakdown to the default selections derived from Companies House."
          leftButton={{
            label: "cancel",
            onclickFunction: () => this.props.dispatchSetConfirmationModal(false)
          }}
          rightButton={{
            label: "reset",
            onclickFunction: () => this.industryMixConfirmationReset()
          }}
          modalClassName="industry-mix-confirmation-modal"
          backdropClassName="industry-mix-confirmation-backdrop"
          toggle={this.toggleIndustryMixConfirmationModal}
          backdrop="static"
          buttonsAlign="center"
        />}
        {industryMixLoader.show &&
          <IndustryMixLoader message={industryMixLoader.message} />
        }
        {buyCrModalObj && buyCrModalObj.toggleStatus && <BuyCrModal toggleModal={this.props.dispatchToggleBuyCrModal} />}
        {toggleCreateModal && <WatchListCreateEditModal
          modalHeading="Create WatchList"
          label1='Watchlist Name'
          label2='Description (optional)'
          eventPage="company_profile"
          dispatchSendActivityTracking={dispatchSendActivityTracking}
          closeModalFunc={() => this.handleActivityTrackingAndToggleStatus(false, CREATE_WATCHLIST_MODAL_CLOSE_BTN_CLICK)}
          buttonComp={{
              YellowBgBtnObj: {
                  Comp: YellowBgButton,
                  btnCss: 'watch-list-create-modal-create-btn text-uppercase white-color-text',
                  onBtnClick: () => this.props.dispatchSetCreateWatchListModalToggleStatus(false),
                  text: 'Create'
              },
              YellowBorderBtnObj: {
                  Comp: YellowBorderButton,
                  btnCss: 'watch-list-create-modal-cancel-btn yellow-E1A132-text text-uppercase',
                  onBtnClick: () => this.handleActivityTrackingAndToggleStatus(false, CREATE_WATCHLIST_MODAL_CANCEL_BTN_CLICK),
                  text: 'Cancel'
              }
          }}
        />}
        {toggleUploadCsvToWatchListModal && <WatchListUploadCsvModal 
         onClose={() => this.props.dispatchSetUploadCsvToWatchListModalToggleStatus(false)}
         heading={"Watchlist Name"}
         description={"Description"}
         upload="CSV File"
         />}
         {filterRestrictionModal?.status && <FilterRestrictionModal
            modalInfo={filterRestrictionModal?.modalInfo}
            filterRestrictionBtnClick={this.handleRedirection}
            toggle={() => this.props.dispatchSetFilterRestrictionModal(!filterRestrictionModal.status)}
         />}
         {this.props.showContactDetailsModal.status && <ShowContactDetailsModal 
            toggle={() => this.handleContactModalToggle()}
            eventPage={this.props.showContactDetailsModal?.companyInfo?.eventPage || ""}
            companyInfo={this.props.showContactDetailsModal?.companyInfo}
            handleActivityTracking={this.props.dispatchSendActivityTracking}
         />}
      </div >
    );
  }
}
const mapStateToProps = (state) => ({
  subscriptionReturnObj: state.subscription.subscriptionReturnObj,
  redirectToStripe: state.subscription.redirectToStripe,
  downloadModalDataObj: state.subscription.downloadModalDataObj,
  screenProperty: state.common.screen,
  actTrkToken: state.common.actTrkToken ? state.common.actTrkToken : "",
  loginSignupToggleStatus: state.loginSignup,
  shareUrlObj: state.common.shareUrlObj,
  isComingSoonModalOpen: state.common.isComingSoonModalOpen,
  whiteLabelConfig: state.common.whiteLabelConfig,
  menu: state.menu,
  contentShareModal: state.common.contentShareModal,
  subscriptionModal: state.subscription.subscriptionModal,
  planDetailValues: state.subscription.planDetailValues,
  submitInterestModalStatus: state.company.submitInterestModalStatus,
  resetPaswordSuccessMessage: state.loginSignup.resetPaswordSuccessMessage,
  resetPasswordErrorMessage: state.loginSignup.resetPasswordErrorMessage,
  forgotPasswordEmailError: state.loginSignup.forgotPasswordEmailError,
  forgotPasswordEmailSuccess: state.loginSignup.forgotPasswordEmailSuccess,
  loading: state.common.loading,
  companyProfile: state.company.companyProfile,
  userInfo: state.userProfile.userInfo,
  industryProfile: state.industryProfile,
  choosePackageModalData: state.common.choosePackageModalData,
  seeMoreModalObj: state.common.seeMoreModalObj,
  toggleStatus: state.common.commonMsgModalInfoObj.toggleStatus,
  industryMixUpdateModal: state.company.industryMixUpdateModal,
  resetIndustryMixChangesError: state.company.resetIndustryMixChangesError,
  saveIndustryChangesError: state.company.saveIndustryChangesError,
  industryMixUpdateSuccess: state.company.industryMixUpdateSuccess,
  industryListForMix: state.searchReducer.industryListForMix,
  addIndustryMixModal: state.company.addIndustryMixModal,
  companyValuation: state.company.companyValuation,
  industryMixLoader: state.company.industryMixLoader,
  showConfirmationModal: state.common.showConfirmationModal,
  buyCrModalObj: state.company.buyCrModalObj,
  noFinancialValuationModalObj: state.company.noFinancialValuationModal,
  restrictAccessForProsperUser: state.userProfile.restrictAccessForPrsoperUser,
  industryEditFlag: state?.businessPlan?.businessPlanOnboardObj.industryEditFlag,
  businessPlanUpdatedIndustry: state?.businessPlan?.businessPlanOnboardObj?.industryList,
  businessPlanObj: state?.businessPlan?.businessPlanOnboardObj,
  businessTempIndustryInsightData: state.businessPlanTemplate.businessPlanTemplateMenuData.analysis.children.industryInsight.data || {},
  selectedCompetitorAnalysis: state.competitiveAnalysis.currentSelectedCompetitor,
  companyValuationData : state.companyValuation,
  bsuniqueId: state.businessPlanTemplate.uniqueId,
  isUserAsGooglBot: state.common.isUserAsGooglBot,
  companyProfileError: state.company.companyProfileError,
  accessTokenStatus: state.common.accessTokenStatus,
  toggleCreateModal: state.watchListReducer?.toggleCreateWatchListModal,
  toggleUploadCsvToWatchListModal: state.watchListReducer?.toggleUploadCsvToWatchlistModal,
  filterRestrictionModal: state.searchReducer?.filterRestrictionModal,
  showContactDetailsModal: state.company.showContactDetailsModal
})
const mapDispatchToProps = (dispatch) => ({
  dispatchGetUserProfile: () => dispatch(getUserProfileRequest()),
  dispatchCallOnloadApi: (latestTid) => dispatch(callOnloadApi(latestTid)),
  dispatchHandlePaymentSuccessErrorAction: (paymentObj) => dispatch(handlePaymentSuccessErrorAction(paymentObj)),
  dispatchResetStripeSessionReturnDataAction: () => dispatch(resetStripeSessionReturnDataAction()),
  dispatchToggleDownloadLoadingModal: (toggleStatus) => dispatch(toggleCRDownloadLoadingModal(toggleStatus)),
  dispatchSetScreenWidthHeight: (screenPropObj) => dispatch(setScreenWidthHeight(screenPropObj)),
  dispatchCheckStorageAndSendTrkDataAction: () => dispatch(checkStorageAndSendTrkDataAction()),
  dispatchCallTrackerAPIAction: (userTrackerObj) => dispatch(callTrackerAPIAction(userTrackerObj)),
  dispatchToggleSignupModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleSignupModal(toggleStatus, trackingInfo, onSuccessRender)),
  dispatchToggleLoginModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleLoginModal(toggleStatus, trackingInfo, onSuccessRender)),
  dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
  dispatchSetLoading: (loadingObj) => dispatch(setLoading(loadingObj)),
  dispatchToggleContentShareModal: toggleStatus => dispatch(toggleContentShareModal(toggleStatus)),
  dispatchToggleSingleProductModal: modalDataObj => dispatch(toggleSingleProductModal(modalDataObj)),
  dispatchToggleSeeMoreModel: (seeMoreModalObj) => dispatch(toggleSeemoreModal(seeMoreModalObj)),
  dispatchGetIndustryProfileSearchResult: (searchedText, eventPage) => dispatch(getIndustryProfileSearchResult(searchedText, eventPage)),
  // dispatchSetChoosePackageModalData: (modalData) => dispatch(setChoosePackageModalData(modalData)),
  dispatchGetLocationProfileSearchResult: searchText => dispatch(getLocationProfileSearchRequest(searchText)),
  dispatchToggleBuyCrModal: (modalDataObj) => dispatch(toggleBuyCreditReportModal(modalDataObj)),
  dispatchSaveIndustryMixChanges: industryMixObj => dispatch(saveIndustryMixChanges(industryMixObj)),
  dispatchSetIndustryMixUpdateSuccess: status => dispatch(setIndustryMixUpdateSuccess(status)),
  dispatchResetIndustryMixChangesFailed: error => dispatch(resetIndustryMixChangesFailed(error)),
  dispatchSetIndustryMixUpdateModal: (toggleStatus, modalTextsObj) => dispatch(setIndustryMixUpdateModal(toggleStatus, modalTextsObj)),
  dispatchSetConfirmationModal: toggleStatus => dispatch(setConfirmationModal(toggleStatus)),
  dispatchSetIndustryMixLoader: toggleStatus => dispatch(setIndustryMixLoader(toggleStatus)),
  dispatchResetIndustryMixChanges: (companyId, name, type, resetType) => dispatch(resetIndustryMixChanges(companyId, name, type, resetType)),
  dispatchSaveIndustryMixChangesFailed: error => dispatch(saveIndustryMixChangesFailed(error)),
  dispatchSetUpdatedMixIndustries: (indMix) => dispatch(setUpdatedMixIndustries(indMix)),
  dispatchToggleNoFinancialValuationModal: (infoObj) => dispatch(noFinancialValuationModal(infoObj)),
  dispatchGetIndustryFilterDataRequest: () => dispatch(getIndustryFilterDataRequest()),
  dispatchSetBusinessPlanBasicData: (editIndustryObj) => dispatch(setBusinessPlanOnboardObj(editIndustryObj)),
  dispatchSetBussTempMainPageData: (pageObj) => dispatch(setBussTempMainPageData(pageObj)),
  disaptchGetIndustryDetails: sicCodes => dispatch(getIndustryDetails(sicCodes)),
  dispatchSetAccessTokenStatus: accesstokenCookie => dispatch(setAccessTokenStatus(accesstokenCookie)),
  dispatchSetCreateWatchListModalToggleStatus: toggleStatus => dispatch(setCreateWatchListModalToggleStatus(toggleStatus)),
  dispatchSetAddWatchListModalToggleStatus: toggleStatus => dispatch(setAddWatchListModalToggleStatus(toggleStatus)),
  dispatchSetUploadCsvToWatchListModalToggleStatus: toggleStatus => dispatch(setUploadCsvToWatchListModalToggleStatus(toggleStatus)),
  dispatchSetFilterRestrictionModal: (status, modalInfo = '') => dispatch(setFilterRestrictionModal(status, modalInfo)),
  dispatchToggleFeatureLockStatus: toggleStatus => dispatch(toggleFeatureLockStatus(toggleStatus)),
  dispatchShowContactDetailsModal: (status) => dispatch(toggleContactDetailsModal(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(App)
