import { call, put, takeLatest } from "redux-saga/effects";
import { GET_INDUSTRY_PROFILE_REQUEST, GET_INDUSTRY_PROFILE_SEARCH_RESULT } from "../redux/actions/actionConstants";
import { getIndustryProfileApiCall, getIndustryProfileSearchApiCall } from '../api/industryProfileApi';
import { getIndustryProfileFailed, getIndustryProfileSuccess, getIndustryProfileSearchSuccess,
    getIndustryProfileSearchFailed } from "../redux/actions/industryProfileAction";
import { sendActivityTracking } from '../redux/actions/commonAction';
import { ET_INDUSTRY_PROFILE_SEARCH_SUSSESS, ET_INDUSTRY_PROFILE_SEARCH_FAILED } from "../common/trackingConstants";

function* getIndustryProfileSaga(action) {
    const {
        industryId = "",
        country = ""
    } = action.payload;
    try {
        let response = yield call(getIndustryProfileApiCall, industryId, country);
        if (response.error) {
            yield put(getIndustryProfileFailed(response.message));
        } else {
            yield put(getIndustryProfileSuccess(response.data));
            window.scrollTo(0,0);
        }
    } catch (error) {
        console.log('inside getIndustryProfileSaga ', error);
    }
}

export function* watchIndustryProfileSaga() {
    yield takeLatest(GET_INDUSTRY_PROFILE_REQUEST, getIndustryProfileSaga);
}

function* getIndustryProfileSearchSaga(action) {
    const {
        searchedText = "",
        eventPage = ""
    } = action.payload;
    try {
        if(searchedText === ""){
            yield put(getIndustryProfileSearchSuccess([]))
        } else {
        let response = yield call(getIndustryProfileSearchApiCall, searchedText);
        if (response.error) {
            yield put(getIndustryProfileSearchFailed(response.message));
            yield put(sendActivityTracking({
                eventPage,
                eventType: ET_INDUSTRY_PROFILE_SEARCH_FAILED,
                attribute1: searchedText,
                attribute2: window.location.pathname
            }));

        } else {
            yield put(getIndustryProfileSearchSuccess(response.data));
            yield put(sendActivityTracking({
                eventPage,
                eventType: ET_INDUSTRY_PROFILE_SEARCH_SUSSESS,
                attribute1: searchedText,
                attribute2: window.location.pathname
            }));
        }
    }
    } catch (error) {
        console.log('inside getIndustryProfileSearchSaga ', error);
    }
}

export function* watchIndustryProfileSearchSaga() {
    yield takeLatest(GET_INDUSTRY_PROFILE_SEARCH_RESULT, getIndustryProfileSearchSaga);
}